import React, { Component } from "react";
import { View, ScrollView, Text, Image } from "react-native";
import { ICarouselProps, ICarouselState } from "./interface";
import { hs, wp, ws, styles } from "./styles";
import { Button } from "../index";
import COLORS from "../../utils/colors";

class MobileCarousel extends Component<ICarouselProps, ICarouselState> {
  constructor(props: ICarouselProps) {
    super(props);
    this.state = {};
  }

  renderItemEmpty = ({ item, index }: any) => {
    return (
      <>
        {item?.image ? (
          <Image
            source={
              typeof item?.image === "string"
                ? { uri: item?.image }
                : item?.image
            }
            style={[
              styles.designContinerIamge,
              this?.props?.designContinerImageStyle,
            ]}
          ></Image>
        ) : (
          <View
            style={[styles.designContiner, this?.props?.designContinerStyle]}
            key={index}
          />
        )}
      </>
    );
  };

  renderItemCard = ({ item, index }: any) => {
    const {
      blockViewStyle,
      titleTextStyle,
      descTextStyle,
      priceBlockStyle,
      priceTextStyle,
    } = this?.props;
    return (
      <View style={[styles.mainView]}>
        <View style={[styles.blockView, blockViewStyle]}>
          <Text style={[styles.titleText, titleTextStyle]}>
            {item?.titleText}
          </Text>
          <Text style={[styles.DescText, descTextStyle]}>{item?.DescText}</Text>
        </View>
        <View>
          <Text style={[styles.titleText, titleTextStyle]}>
            {item?.titleText2}
          </Text>
          <Text style={[styles.DescText, descTextStyle]}>
            {item?.DescText2}
          </Text>
          <View style={[styles.priceBlock, priceBlockStyle]}>
            <Text style={[styles.priceText, priceTextStyle]}>
              {item?.price}
            </Text>
            {item?.children}
          </View>
        </View>
      </View>
    );
  };

  renderItem = ({ item, index }: any) => {
    const { renderItem, renderItemType } = this.props;
    if (renderItem) return renderItem({ item, index });
    else if (renderItemType == "card")
      return this.renderItemCard({ item, index });
    else return this.renderItemEmpty({ item, index });
  };

  render() {
    const { data, fHeight, fWidth, containerStyle, isDotsVisible } = this.props;
    return (
      <View
        style={[
          styles.container,
          fHeight ? { height: fHeight } : {},
          fWidth ? { width: fWidth } : {},
          containerStyle,
        ]}
      >
        <ScrollView
          horizontal={true}
          pagingEnabled={true}
          scrollEventThrottle={16}
          showsHorizontalScrollIndicator={false}
        >
          {data?.map((item, index) => {
            return this.renderItem({ item, index });
          })}
        </ScrollView>
        {isDotsVisible ? (
          <View style={styles.dotContainer}>
            {data?.map((e, i) => {
              const size = ws(5),
                backgroundColor = "#A666FF";
              const dotStyle = { backgroundColor, width: size, height: size };
              return <View style={[styles.dot, dotStyle]} key={i} />;
            })}
          </View>
        ) : null}
      </View>
    );
  }
}

export default MobileCarousel;

/**
 * 
 * 
Example: 
    import MobileCarousel from './MobileCarousel';

    Varient 1:
    <MobileCarousel
        fHeight={234}
        fWidth={228}
        isDotsVisible={true}
        data={["1", "1", "1", "1", "1", "1", "1", "1", "1"]}
        renderItem={({ item, index }) => {
            return (
            <View
                style={{ height: 224, width: 224, borderRadius: 5, backgroundColor: '#F1F5F9', marginRight: 4, marginVertical: 5 }}
                key={index}
            >
            </View>
            )
        }}
    />

    Varient 2:
          <MobileCarousel
            // fHeight={600}
            // fWidth={Dimensions.get('window').width}
            isDotsVisible={true}
            renderItemType={'card'}
            data={[
            {
                id: 1,
                title: 'Title',
                DescText: 'Description: ipsum dolor sit amet, cons adipig',
                titleText2: 'Title',
                DescText2: 'Description: ipsum dolor sit amet, cons adipig elit.',
                image: 'https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-980x653.jpg',
                textlabel: 'textLabel',
                price: '$ Price'
            },
            {
                id: 2,
                titleText2: 'Title',
                DescText2: 'Description: ipsum dolor sit amet, cons adipig elit.',
                image: 'https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-980x653.jpg',
                textlabel: 'textLabel',
            },
            {
                id: 3,
                titleText2: 'Title',
                DescText2: 'Description: ipsum dolor sit amet, cons adipig elit.',
                image: 'https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-980x653.jpg',
                textlabel: 'textLabel',
            },
            {
                id: 4,
                titleText2: 'Title',
                DescText2: 'Description: ipsum dolor sit amet, cons adipig elit.',
                image: 'https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-980x653.jpg',
                textlabel: 'textLabel',
            }
            ]}
        />
 * 
 */
