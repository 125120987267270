import { StyleSheet } from "react-native";
import COLORS from "../../../utils/colors";

export default StyleSheet.create({
  radioButtonStyle: {
    width: 20,
    height: 20,
    borderRadius: 20 / 2,
    borderWidth: 1,
    borderColor: COLORS.COLOR_DISABLE_GREY,
    justifyContent: "center",
  },
  radioButtonDisButtonStyle: {
    borderWidth: 1,
    borderColor: COLORS.COLOR_GRAY_PLACEHOLDER,
    width: 20,
    height: 20,
    borderRadius: 20 / 2,
    justifyContent: "center",
  },
  radioButtonDisSelButtonStyle: {
    borderWidth: 5,
  },
  radioButtonSelectedButtonStyle: {
    width: 20,
    height: 20,
    borderRadius: 20 / 2,
    backgroundColor: COLORS.COLOR_MEDIUMBLUE,
    justifyContent: "center",
  },
  radioButtonTextStyle: {
    fontSize: 16,
    color: COLORS.COLOR_LISTTEXT,
    marginLeft: 10,
  },
  radioButtonViewStyle: {
    flexDirection: "row",
    marginBottom: 15,
    alignItems: "center",
  },
  symbol: {
    width: 10,
    height: 10,
    alignSelf: "center",
  },
  radioButtonDisableButtonStyle: {
    width: 20,
    height: 20,
    borderRadius: 20 / 2,
    borderWidth: 1,
    borderColor: COLORS.COLOR_GREY_INPUT,
    justifyContent: "center",
  },
  radioButtonDisableSelectedButtonStyle: {
    width: 20,
    height: 20,
    borderRadius: 20 / 2,
    borderWidth: 5,
    borderColor: COLORS.COLOR_GREY_INPUT,
    justifyContent: "center",
  },
  disableRadioButtonTextStyle: {
    fontSize: 16,
    color: COLORS.COLOR_GRAY_PLACEHOLDER,
    marginLeft: 5,
  },
  radioButtonInnerView: {
    width: 8,
    height: 8,
    borderRadius: 8 / 2,
    backgroundColor: "white",
    alignSelf: "center",
  },
  radioButtonGroupTitleStyle: {
    fontSize: 14,
    color: COLORS.COLOR_GRAY,
    marginBottom: 15,
  },
  linearradioButtonSelectedButtonStyle: {
    width: 20,
    height: 20,
    borderRadius: 20 / 2,
    justifyContent: "center",
  },
});
