import React, { Component } from "react";
import { View, TouchableOpacity, Image, Text } from "react-native";
import { IDropDown, IDropDownState } from "./interface";
import { styles } from "./styles";

export class DropDown extends Component<IDropDown, IDropDownState> {
  constructor(props: IDropDown) {
    super(props);
    this.state = {
      isVisible: props.isVisibleInital || false,
      value: null,
    };
  }

  onPressPlaceHolder = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  onValuePress = (item: any) => {
    this.setState({ value: item, isVisible: false });
    this.props.onSelect(item)
  };

  RenderAccordionHeader = () => {
    const {
      placeHolder,
      dropdownIcon,
      placeHolderContainer,
      textStyle,
      headerTextStyle,
      headerImageStyle,
    } = this.props;
    const { value } = this.state;
    return (
      <TouchableOpacity
        onPress={this.onPressPlaceHolder}
        style={[styles.placeHolderContainer, placeHolderContainer]}
      >
        {value?.image ? (
          <View style={styles.iconContainer}>
            <Image
              style={styles.image}
              source={
                typeof value.image === "string"
                  ? { uri: value.image }
                  : value.image
              }
            />
          </View>
        ) : (
          <Text style={[styles.placeholderTextStyle, headerTextStyle]}>
            {value?.name ? value?.name : placeHolder}
          </Text>
        )}
        <View style={styles.imageContainer}>
          <Image
            style={[styles.image, headerImageStyle]}
            source={
              typeof dropdownIcon === "string"
                ? { uri: dropdownIcon || "https://i.imgur.com/aXzX4Z7.png" }
                : dropdownIcon
            }
          />
        </View>
      </TouchableOpacity>
    );
  };

  RenderAccordionContent = ({ item, index }: any) => {
    const {
      renderAccordionContent,
      listContainer,
      textStyle,
      contentImageStyle,
      selectedListContainer,
    } = this.props;
    const { value } = this.state;
    const isActive = item?.key == value?.key;
    if (renderAccordionContent)
      return renderAccordionContent({ item, index, isActive });
    else
      return (
        <TouchableOpacity
          onPress={() => this.onValuePress(item)}
          style={
            isActive
              ? [styles.selectedListContainer, selectedListContainer]
              : [styles.listContainer, listContainer]
          }
          key={index}
        >
          {item.image ? (
            <View style={styles.iconContainer}>
              <Image
                style={[styles.image, contentImageStyle]}
                source={
                  typeof item.image === "string"
                    ? { uri: item.image }
                    : item.image
                }
              />
            </View>
          ) : null}
          <Text style={[styles.placeholderTextStyle, textStyle]}>
            {item?.name}
          </Text>
        </TouchableOpacity>
      );
  };

  render() {
    const {
      options,
      renderAccordionHeader,
      container,
      contentContainer,
      zIndex,
    } = this.props;
    const { isVisible } = this.state;
    return (
      <View style={[styles.container, zIndex ? { zIndex } : {}, container]}>
        {renderAccordionHeader
          ? renderAccordionHeader()
          : this.RenderAccordionHeader()}
        {options.length > 0 && isVisible ? (
          <View style={[styles.contentContainer, contentContainer]}>
            {options.map((item: any, index: any) => {
              return this.RenderAccordionContent({ item, index });
            })}
          </View>
        ) : null}
      </View>
    );
  }
}

export default DropDown;
