import React from "react";
import {
  TouchableOpacity,
  Text,
  TextStyle,
  View,
  Platform,
  ViewStyle,
} from "react-native";
import { LinearGradient, Stop, Rect, Svg, Defs } from "react-native-svg";

interface GradientButtonProps {
  onPress?: () => void;
  colors: string[];
  textStyle?: TextStyle;
  style?: any;
  children?: React.ReactNode;
}

const LinearGradientComponent: React.FC<GradientButtonProps> = ({
  onPress,
  colors,
  textStyle,
  children,
  style = { borderRadius: 25 },
}) => {
  const renderWebButton = () => (
    // <TouchableOpacity>
    <div
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: style?.borderRadius,
        backgroundImage: `linear-gradient(to right, ${colors[0]}, ${colors[1]})`,
      }}
      onClick={onPress}
    >
      {children}
    </div>
    // </TouchableOpacity>
  );

  const renderNativeButton = () => (
    <View style={style}>
      <Svg width="100%" height="100%">
        <Defs>
          <LinearGradient id="grad" x1="0" y1="0" x2="100%" y2="0">
            {colors.map((color, index) => (
              <Stop
                key={index}
                offset={`${(index * 100) / (colors.length - 1)}%`}
                stopColor={color}
                stopOpacity={colors.length ? (index == 0 ? "0" : "1") : "1"}
              />
            ))}
          </LinearGradient>
        </Defs>
        <Rect
          rx={style.borderRadius}
          ry={style.borderRadius}
          width="100%"
          height="100%"
          fill={`url(#grad)`}
        />
        <View
          style={{
            height: "100%",
            // width: '100%',
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            // borderRadius: 20,
          }}
        >
          {children}
        </View>
      </Svg>
    </View>
  );

  return Platform.OS === "web" ? renderWebButton() : renderNativeButton();
};

export default LinearGradientComponent;
