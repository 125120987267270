import React, { Component } from "react";
import {
  StyleProp,
  TextStyle,
} from "react-native";
import Radiobuttonmultiple from "./component/radiobuttonmultiple";
import RadioButtonSingle from "./component/radiobuttonsingle";

// multipleRadioButton
export interface RadioButtonMultipleProps {
  radioButtonGroup?: boolean;
  radioButtonGroupTitle?: string;
  radioButtonGroupData?: Array<object>;
}
// single check box
export interface RadioButtonSingleProps {
  radioButtonGroup?: boolean;
  radioButtonText?: string
  onRadioButtonPress?: () => void;
  isRadioButtonSelected?: boolean;
  isRadioButtonDisable?: boolean;
  radioButtonDisableSelected?: boolean;
}

export interface RadioButtonProps {
  radioButtonStyle?: Object;
  radioButtonTextStyle?: StyleProp<TextStyle>;
  radioButtonDisableSelectedButtonStyle?: Object;
  radioButtonDisableButtonStyle?: Object;
  disableRadioButtonTextStyle?: Object;
  radioButtonGroupTitleStyle?: StyleProp<TextStyle>;
  viewGradientColorStyle?: Array<string>
}


class RadioButton extends Component<
  RadioButtonProps & (RadioButtonMultipleProps | RadioButtonSingleProps)
>{
  render() {
    return (
      <>
        {this?.props?.radioButtonGroup == false ? (
          <RadioButtonSingle {...this?.props} />
        ) : (
          <Radiobuttonmultiple {...this?.props} />
        )}
      </>
    )
  }
}
export default RadioButton
