import React, { Component } from "react";
import {
  Image,
  TouchableOpacity,
  View,
  Text,
  ViewStyle,
  TextStyle,
} from "react-native";
import styles from "./styles";
import COLORS from "../../utils/colors";

interface SegmentedCtrlItemProps {
  size?: number;
  textStyle?: TextStyle;
  item: {
    id: string;
    name: string;
    icon?: any;
  };
  index: number;
  itemStyle?: ViewStyle;
  selectedId?: string;
  itemSelectdBgColor?: string;
  itemSelectdTxtColor?: string;
  iconStyle?: object;
  onPressItem: (item: any, index: number) => void;
}

class SegmentedCtrlItem extends Component<SegmentedCtrlItemProps> {
  render() {
    const {
      size,
      textStyle,
      item,
      index,
      itemStyle,
      selectedId,
      itemSelectdBgColor = "",
      itemSelectdTxtColor = "",
      iconStyle,
      onPressItem,
    } = this.props;

    return (
      <TouchableOpacity onPress={() => onPressItem(item, index)}>
        <View
          style={[
            styles.button,
            { height: size },
            { borderWidth: selectedId === item?.id ? 1 : 0 },
            {
              borderColor: itemStyle?.borderColor
                ? itemStyle.borderColor
                : COLORS.COLOR_BLUE,
            },
            {
              backgroundColor:
                selectedId === item?.id
                  ? itemSelectdBgColor !== ""
                    ? itemSelectdBgColor
                    : COLORS.COLOR_SEGMNTD
                  : COLORS.COLOR_INFO,
            },
            { borderRadius: selectedId === item?.id ? 1000 : 0 },
            itemStyle,
          ]}
        >
          {item?.icon && (
            <Image
              source={
                typeof item?.icon === "string"
                  ? { uri: item?.icon }
                  : item?.icon
              }
              style={[
                styles.calendarIcon,
                iconStyle,
                {
                  tintColor:
                    selectedId === item?.id
                      ? COLORS.COLOR_MEDIUMBLUE
                      : COLORS.COLOR_GRAY,
                },
              ]}
            />
          )}
          <Text
            style={[
              styles.text,
              {
                color:
                  selectedId === item?.id
                    ? itemSelectdTxtColor !== ""
                      ? itemSelectdTxtColor
                      : COLORS.COLOR_MEDIUMBLUE
                    : COLORS.COLOR_GRAY,
              },
              textStyle,
            ]}
          >
            {item?.name}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
}

export default SegmentedCtrlItem;
