import React, { Component } from "react";
import { View, Image, TouchableOpacity, Text } from "react-native";
import styles from "./styles";
import Button from "../Button";
import STRINGS from "../../utils/strings";
import STRINGS_TESTCASE from "../../utils/stringsTestCase";

export interface PopoverConfirmComptProps {
  containerStyle?: {};
  iconStyle?: {};
  titleText?: string;
  titleStyle?: {};
  descriptionText?: string;
  descriptionStyle?: {};
  btnCancelStyle?: {};
  txtCancelStyle?: {};
  btnDoneStyle?: {};
  txtDoneStyle?: {};
  closeIcon?: string;
  gradientColor1: string;
  gradientColor2: string;
}

interface PopoverState {
  isModalVisible: boolean;
}

class PopoverConfirmCompt extends Component<
  PopoverConfirmComptProps,
  PopoverState
> {
  constructor(props: PopoverConfirmComptProps) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  onPressCloseIcon = () => {};

  onPressCancel = () => {};

  onPressDone = () => {};

  render() {
    const {
      containerStyle,
      iconStyle,
      titleText = STRINGS.DEFAULT_TXT_POPOVR_1,
      titleStyle,
      descriptionText = STRINGS.DEFAULT_TXT_POPOVR_2,
      descriptionStyle,
      btnCancelStyle,
      txtCancelStyle,
      btnDoneStyle,
      txtDoneStyle,
      closeIcon = "https://i.imgur.com/amHs6X5.png",
      gradientColor1,
      gradientColor2,
      ...props
    } = this.props;
    const { isModalVisible } = this.state;

    return (
      <View style={[styles.innerContainer, containerStyle]}>
        <TouchableOpacity
          onPress={() => this.onPressCloseIcon()}
          testID={STRINGS_TESTCASE.POPOVER_INPUT_COMPONENT_ICON}
          style={styles.iconContainer}
        >
          <Image
            source={
              typeof closeIcon === "string" ? { uri: closeIcon } : closeIcon
            }
            style={[iconStyle, styles.crossIcon]}
          />
        </TouchableOpacity>
        <Text style={[styles.txtTitle, titleStyle]}>{titleText}</Text>
        <Text style={[styles.txtDescription, descriptionStyle]}>
          {descriptionText}
        </Text>
        <View style={styles.horizontalLine} />
        <View style={styles.viewBtnContainer}>
          <Button
            style={[styles.btnCancelDone, btnCancelStyle]}
            text={STRINGS.CANCEL}
            textStyle={[styles.txtCancel, txtCancelStyle]}
            onPress={() => this.onPressCancel()}
            theme={"secondary"}
          />
          <Button
            style={btnDoneStyle}
            text={STRINGS.DONE}
            textStyle={[styles.txtDelAccount, txtDoneStyle]}
            onPress={() => this.onPressDone()}
            gradientColor1={gradientColor1}
            gradientColor2={gradientColor2}
            theme="primary"
          />
        </View>
      </View>
    );
  }
}

export default PopoverConfirmCompt;
