import React, { Component } from "react";
import { View } from "react-native";
import STRINGS from "../../../utils/strings";
import { styles } from "./styles";
import { LineChart } from "../../../modules/react-native-chart-kit/src";

interface Dataset {
  data: number[];
  strokeWidth: number;
  strokeColor: string;
  fill?: string;
}

interface BasicAreaChartProps {
  data: {
    labels: string[];
    datasets: Dataset[];
  };
  chartConfig: {
    backgroundGradientFrom: string;
    backgroundGradientTo: string;
    decimalPlaces: number;
    useShadowColorFromDataset: boolean;
    color: (opacity: number) => string;
    propsForDots: {
      r: string;
      strokeWidth: string;
    };
    propsForBackgroundLines: {
      strokeDasharray: string;
    };
  };
  height: number;
  width: number;
  showGridLines: boolean;
  containerStyle?: Object;
  lineChartStyle?: Object;
}

class BasicAreaChart extends Component<BasicAreaChartProps> {
  constructor(props: BasicAreaChartProps) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      data,
      chartConfig,
      width,
      height,
      showGridLines,
      containerStyle,
      lineChartStyle,
    } = this.props;

    const { labels, datasets } = data;

    const chartData = {
      labels,
      datasets: datasets.map((dataset) => ({
        data: dataset.data,
        strokeWidth: dataset.strokeWidth,
        color: () => dataset.strokeColor,
        fill: dataset.fill,
      })),
    };

    const updatedChartConfig = {
      ...chartConfig,
      labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
    };

    return (
      <View
        style={[styles.container, containerStyle]}
        testID={STRINGS.BASIC_AREA_CHART_TESTID}
      >
        <LineChart
          style={lineChartStyle}
          data={chartData}
          width={width}
          height={height}
          chartConfig={updatedChartConfig}
          withInnerLines={showGridLines}
          withHorizontalLines={true}
          withVerticalLines={false}
          withDots={false}
        />
      </View>
    );
  }
}

export default BasicAreaChart;
