import React, { Component } from "react";
import { View, Image, Text } from "react-native";
import styles from "./styles";
import STRINGS from "../../utils/strings";

export interface HeaderProps {
  headerText?: string;
  headerIcon?: string;
  headerViewStyle?: object;
  headerTextStyle?: object;
  leftIcon?: string;
  leftIconStyle?: object;
  rightIcon?: string;
  rightIconStyle?: object;
  buttonText?: string;
  containerStyle?: object;
  btnTextStyle?: object;
  leftIconContainer?: object;
  rightIconContainer?: object;
  headerIconStyle?: object;
  headerIconView?: object;
}

class Header extends Component<HeaderProps> {
  render() {
    const {
      headerText,
      headerIcon,
      headerTextStyle,
      leftIcon,
      leftIconStyle,
      rightIcon,
      rightIconStyle,
      buttonText,
      containerStyle,
      btnTextStyle,
      headerViewStyle,
      headerIconStyle,
      leftIconContainer,
      rightIconContainer,
      headerIconView,
    } = this.props;

    return (
      <View style={[styles.mainView, styles.shadowProp, containerStyle]}>
        <View style={[styles.leftIconView, leftIconContainer]}>
          {leftIcon && (
            <Image
              source={
                typeof leftIcon === "string" ? { uri: leftIcon } : leftIcon
              }
              style={[styles.leftIcon, leftIconStyle]}
            />
          )}
        </View>
        <View style={[styles.headerCenterView, headerViewStyle]}>
          {headerIcon ? (
            <View style={[styles.headerIconView, headerIconView]}>
              <Image
                source={
                  typeof headerIcon === "string"
                    ? { uri: headerIcon }
                    : headerIcon
                }
                style={[styles.headerIcon, headerIconStyle]}
              />
            </View>
          ) : headerText ? (
            <Text style={[styles.headerText, headerTextStyle]}>
              {headerText.slice(0, 30)}
            </Text>
          ) : null}
        </View>
        <View style={[styles.rightIconView, rightIconContainer]}>
          {rightIcon ? (
            <Image
              source={
                typeof rightIcon === "string" ? { uri: rightIcon } : rightIcon
              }
              style={[styles.rightIcon, rightIconStyle]}
            />
          ) : buttonText ? (
            <View testID={STRINGS.HEADER_BUTTON}>
              <Text style={[styles.btnTextStyle, btnTextStyle]}>
                {buttonText}
              </Text>
            </View>
          ) : null}
        </View>
      </View>
    );
  }
}

export default Header;
