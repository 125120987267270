import React, { Component } from "react";
import { FlatList, ViewStyle, TextStyle } from "react-native";
// import STRINGS_TESTCASE from '../../utils/stringsTestCase';
import styles from "./styles";
import CONSTANTS from "../../utils/constants";
import SegmentedCtrlItem from "./SegmentedCtrlItem";

export interface SegmentedControlProps {
  size?: 'small' | 'medium' | 'big';
  textStyle?: TextStyle;
  dataSet?: Array<Object>;
  iconStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  itemStyle?: ViewStyle;
  itemSelectdBgColor?: string;
  itemSelectdTxtColor?: string;
}

interface SegmentedControlState {
  selectedId: string | undefined;
}

export const getSize = (size: 'small' | 'medium' | 'big') => {
  switch (size) {
    case CONSTANTS.SIZE_SMALL:
      return 32
    case CONSTANTS.SIZE_BIG:
      return 56
    case CONSTANTS.SIZE_MEDIUM:
    default:
      return 44
  }
}


class SegmentedControl extends Component<
  SegmentedControlProps,
  SegmentedControlState
> {
  constructor(props: SegmentedControlProps) {
    super(props);
    this.state = {
      selectedId: undefined,
    };
  }

  onPressItem = (item: any, index: number) => {
    this.setState({ selectedId: item?.id });
  };

  render() {
    const {
      size,
      textStyle,
      dataSet,
      iconStyle,
      containerStyle,
      itemStyle,
      itemSelectdBgColor = "",
      itemSelectdTxtColor = "",
    } = this.props;
    const { selectedId } = this.state;

    return (
      <FlatList
        // testID={STRINGS_TESTCASE.SEGMENTCONTROL_COMPONENT}
        contentContainerStyle={[styles.flatlist, containerStyle]}
        data={dataSet}
        keyExtractor={(item: any) => `${item?.id}`}
        horizontal={true}
        renderItem={({ item, index }) => (
          <SegmentedCtrlItem
            item={item}
            index={index}
            size={getSize(size)}
            textStyle={textStyle}
            iconStyle={iconStyle}
            itemStyle={itemStyle}
            itemSelectdBgColor={itemSelectdBgColor}
            itemSelectdTxtColor={itemSelectdTxtColor}
            onPressItem={(item: any, index: any) =>
              this.onPressItem(item, index)
            }
            selectedId={selectedId}
          />
        )}
      />
    );
  }
}

export default SegmentedControl;
