import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'

const styles = StyleSheet.create({
  calenderStyle: {
    marginLeft: 12,
    backgroundColor: 'white',
    minHeight: 350,
    shadowColor: '#000',
    width: 300,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  imgStyle: {
    height: '100%',
    width: '100%',
    resizeMode: 'stretch',
  },
  renderArrowStyle: { height: 20, width: 20 },
  container: {
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: COLORS.COLOR_WHITE,
    borderRadius: 8,
    borderColor: COLORS.COLOR_MEDIUMBLUE,
    width: '40%',
    marginLeft: 12,
  },
  textInputStyle: {
    fontSize: 16,
    fontWeight: '400',
    height: 40,
    zIndex: 1,
    padding: 10,
  },
  leftIcon: {
    marginLeft: 8,
    color: COLORS.COLOR_GRAY,
    height: 18,
    width: 18,
    textAlign: 'center',
  },
})

export default styles
