import { StyleSheet } from "react-native";
import COLORS from "../../../utils/colors";
import {
  horizontalScale,
  moderateScale,
  verticalScale,
} from "../../../utils/screenRatio";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  legendContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginLeft: 30, 
    marginTop:verticalScale(10)
  },
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: horizontalScale(5),
  },
  legendIcon: {
    width: 11,
    height: 11,
    borderRadius: moderateScale(5),
    marginRight: horizontalScale(3),
  },
  legendLabel: {
    fontSize: moderateScale(7),
    fontWeight: "400",
    // fontFamily:"Inter-Regular",
    color: COLORS.COLOR_COOLGRAY,
  },
});
