import React, { Component } from "react";
import {
  ActivityIndicator,
  TouchableOpacity,
  Image,
  View,
  Text,
} from "react-native";
import styles from "./styles";
import LinearGradientComponent from "../LinearGradient";
import COLORS from "../../utils/colors";
import CONSTANTS from "../../utils/constants";

interface ButtonProps {
  theme?: "primary" | "secondary" | "dark";
  text?: string;
  loading?: boolean;
  style?: {};
  textStyle?: {};
  disabled?: boolean;
  gradientColor1?: string;
  gradientColor2?: string;
  loaderStyle?: {};
  icon?: string;
  iconStyle?: {};
  iconPosition?: "left" | "right";
  onPress?: () => void;
}

interface ButtonState {
  isHovered: boolean;
}
class Button extends Component<ButtonProps, ButtonState> {
  constructor(props: ButtonProps) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }

  primaryButton() {
    const {
      text,
      loading,
      style,
      textStyle,
      disabled,
      gradientColor1 = COLORS.COLOR_MEDIUMBLUE,
      gradientColor2 = COLORS.COLOR_LIGHTBLUE,
      loaderStyle,
      icon,
      iconStyle,
      iconPosition = "left",
      onPress,
      ...props
    } = this.props;
    const { isHovered } = this.state;

    return (
      <TouchableOpacity {...props}>
        {isHovered ? (
          <View
            style={[
              styles.buttonHovered,
              { flexDirection: iconPosition == "left" ? "row" : "row-reverse" },
              style,
            ]}
          >
            {icon && (
              <Image
                source={typeof icon === "string" ? { uri: icon } : icon}
                style={[styles.icon, iconStyle]}
              />
            )}
            {text && (
              <Text
                style={[styles.text, { color: COLORS.COLOR_WHITE }, textStyle]}
              >
                {text}
              </Text>
            )}
          </View>
        ) : loading ? (
          <View style={[styles.buttonLoading, style]}>
            {text && (
              <Text
                style={[
                  styles.text,
                  { color: COLORS.COLOR_LIGHTPINK },
                  textStyle,
                ]}
              >
                {text}
              </Text>
            )}
            <ActivityIndicator
              color={COLORS.COLOR_LIGHTPINK}
              style={[loaderStyle]}
            />
          </View>
        ) : (
          <LinearGradientComponent
            style={{
              borderRadius: 8,
              ...style,
            }}
            colors={[gradientColor2, gradientColor1]}
          >
            <View
              style={[
                styles.buttonSecondary,
                {
                  flexDirection: iconPosition == "left" ? "row" : "row-reverse",
                },
                style,
              ]}
            >
              {icon && !loading && (
                <Image
                  source={typeof icon === "string" ? { uri: icon } : icon}
                  style={[
                    styles.icon,
                    // { tintColor: COLORS.COLOR_MEDIUMBLUE },
                    iconStyle,
                  ]}
                />
              )}
              {text && (
                <Text
                  style={[
                    styles.text,
                    { color: COLORS.COLOR_WHITE },
                    textStyle,
                  ]}
                >
                  {text}
                </Text>
              )}
            </View>
          </LinearGradientComponent>
        )}
      </TouchableOpacity>
    );
  }

  secondaryButton() {
    const {
      text,
      loading,
      style,
      textStyle,
      disabled,
      icon,
      iconStyle,
      iconPosition = "left",
      loaderStyle,
      ...props
    } = this.props;
    const { isHovered } = this.state;

    return (
      <TouchableOpacity {...props}>
        <View
          style={[
            { flexDirection: iconPosition == "left" ? "row" : "row-reverse" },
            {
              backgroundColor: isHovered
                ? COLORS.COLOR_BLUE
                : COLORS.COLOR_LIGHTPURPLE,
            },
            styles.buttonSecondary,
            style,
          ]}
        >
          {icon && !loading && (
            <Image
              source={typeof icon === "string" ? { uri: icon } : icon}
              style={[
                styles.icon,
                // { tintColor: COLORS.COLOR_MEDIUMBLUE },
                iconStyle,
              ]}
            />
          )}
          {text && (
            <Text
              style={[
                styles.text,
                {
                  color: loading
                    ? COLORS.COLOR_MEDIUMBLUE + "50"
                    : COLORS.COLOR_MEDIUMBLUE,
                },
                textStyle,
              ]}
            >
              {text}
            </Text>
          )}
          {loading && (
            <ActivityIndicator
              color={COLORS.COLOR_MEDIUMBLUE}
              style={{ ...loaderStyle }}
            />
          )}
        </View>
      </TouchableOpacity>
    );
  }

  darkButton() {
    const {
      text,
      loading,
      style,
      textStyle,
      disabled,
      icon,
      iconStyle,
      iconPosition = "left",
      loaderStyle,
      ...props
    } = this.props;
    const { isHovered } = this.state;

    return (
      <TouchableOpacity {...props}>
        <View
          style={[
            { flexDirection: iconPosition == "left" ? "row" : "row-reverse" },
            styles.buttonDark,
            style,
          ]}
        >
          {icon && !loading && (
            <Image
              source={typeof icon === "string" ? { uri: icon } : icon}
              style={[
                styles.icon,
                // { tintColor: COLORS.COLOR_MEDIUMBLUE },
                iconStyle,
              ]}
            />
          )}
          {text && (
            <Text
              style={[
                styles.text,
                {
                  color: loading
                    ? COLORS.COLOR_MEDIUMBLUE + "50"
                    : COLORS.COLOR_MEDIUMBLUE,
                },
                textStyle,
              ]}
            >
              {text}
            </Text>
          )}
          {loading && (
            <ActivityIndicator
              color={COLORS.COLOR_MEDIUMBLUE}
              style={[loaderStyle]}
            />
          )}
        </View>
      </TouchableOpacity>
    );
  }

  disableButton() {
    const {
      text,
      style,
      textStyle,
      icon,
      iconStyle,
      iconPosition = "left",
      ...props
    } = this.props;

    return (
      <TouchableOpacity disabled {...props}>
        <View
          style={[
            styles.buttonDisable,
            { flexDirection: iconPosition == "left" ? "row" : "row-reverse" },
            style,
          ]}
        >
          {icon && (
            <Image
              source={typeof icon === "string" ? { uri: icon } : icon}
              style={[
                styles.icon,
                // { tintColor: COLORS.COLOR_MEDIUMBLUE },
                iconStyle,
              ]}
            />
          )}
          {text && (
            <Text
              style={[
                styles.text,
                { color: COLORS.COLOR_DISABLE_GREY },
                textStyle,
              ]}
            >
              {text}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    );
  }

  render() {
    const { disabled, theme } = this.props;

    return disabled
      ? this.disableButton()
      : theme === CONSTANTS.THEME_PRIMARY
      ? this.primaryButton()
      : theme === CONSTANTS.THEME_DARK
      ? this.darkButton()
      : this.secondaryButton();
  }
}

export default Button;
