import React, { Component } from "react";
import {
  View,
  FlatList,
  Image,
  Text,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import styles from "./styles";
import LinearGradientComponent from "../LinearGradient";
import COLORS from "../../utils/colors";

export interface BottomTabProps {
  tabData: Array<object>;
  containerStyle?: object;
  flatListContainer?: object;
  activeIndex?: number;
  selectedIconStyle?: object;
  unSelectedIconStyle?: object;
  selectedTabStyle?: object;
  selectedTextStyle?: object;
  unSelectedTextStyle?: object;
  unselectedTabStyle?: object;
  onIndexChange?: (text: string) => void;
  gradientColor1?: string;
  gradientColor2?: string;
  flatListView?: object;
  noOfTab?: number;
}

export interface itemprops {
  selectedItem?: any;
}

const screenWidth = Dimensions.get("screen").width;

class GradientBottomTab extends Component<BottomTabProps, itemprops> {
  constructor(props: BottomTabProps) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  componentDidMount() {
    const { activeIndex } = this.props;
    this.setState({ selectedItem: activeIndex });
  }

  componentDidUpdate(prevProps: BottomTabProps) {
    if (prevProps.activeIndex !== this.props.activeIndex) {
      this.setState({ selectedItem: this.props.activeIndex });
    }
  }

  onIndexChangeVal = (index: any) => {
    this.setState({ selectedItem: index });
    const { onIndexChange } = this.props;
    if (onIndexChange) {
      onIndexChange(index);
    }
  };

  renderItem = ({ item, index }: any) => {
    const { selectedItem } = this.state;
    const { noOfTab = 4 } = this.props;
    const isSelected = selectedItem === index;

    return (
      <TouchableOpacity
        style={{ width: screenWidth / noOfTab }}
        onPress={() => this.onIndexChangeVal(index)}
      >
        <View
          style={[
            styles.button,
            isSelected
              ? [styles.selectedTab, this.props.selectedTabStyle]
              : this.props.unselectedTabStyle,
          ]}
        >
          <Image
            source={
              typeof item?.img === "string" ? { uri: item?.img } : item?.img
            }
            resizeMode={"contain"}
            style={[
              styles.iconStyle,
              isSelected
                ? [styles.selectedIconStyle, this.props.selectedIconStyle]
                : this.props.unSelectedIconStyle,
            ]}
          />

          <Text
            style={[
              styles.text,
              isSelected
                ? [styles.selectedText, this.props.selectedTextStyle]
                : this.props.unSelectedTextStyle,
            ]}
          >
            {item?.name.slice(0, 12)}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    const {
      tabData,
      flatListContainer,
      flatListView,
      gradientColor1 = COLORS.COLOR_LIGHTBLUE,
      gradientColor2 = COLORS.COLOR_MEDIUMBLUE,
      noOfTab = 4,
    } = this.props;

    return (
      <View style={flatListView}>
        <LinearGradientComponent
          colors={[gradientColor1, gradientColor2]}
          style={{ height: 50 }}
        ></LinearGradientComponent>
        <FlatList
          style={{ position: "absolute" }}
          horizontal
          data={tabData}
          keyExtractor={(item: any) => item.id}
          renderItem={this.renderItem}
          contentContainerStyle={[
            { width: (screenWidth / noOfTab) * tabData.length },
            styles.flatlist,
            flatListContainer,
          ]}
        />
      </View>
    );
  }
}

export default GradientBottomTab;
