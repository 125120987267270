import React, { Component } from "react";
import { View, Text } from "react-native";
import { styles } from "./styles";
import COLORS from "../../../utils/colors";
import STRINGS from "../../../utils/strings";
import { StackedBarChart } from "../../../modules/react-native-chart-kit/src";

interface BarChartProps {
  data: {
    labels: string[];
    legend: string[];
    data: number[][];
    barColors: string[];
  };
  chartConfig: {
    backgroundGradientFrom: string;
    backgroundGradientTo: string;
    decimalPlaces: number;
    barColors: string[];
    barPercentage: number;
    color: (opacity: number) => string;
    propsForBackgroundLines: {
      strokeDasharray: string;
    };
  };
  legendLabels?: string[];
  height: number;
  width: number;
  hideLegend: boolean;
  legendContainerStyle?: Object;
  legendViewStyle?: Object;
  legendIconStyle?: Object;
  legendTextStyle?: Object;
}

class BarCharts extends Component<BarChartProps> {
  constructor(props: BarChartProps) {
    super(props);
  }

  render() {
    const {
      data,
      chartConfig,
      width,
      height,
      hideLegend,
      legendContainerStyle,
      legendViewStyle,
      legendIconStyle,
      legendTextStyle,
    } = this.props;

    return (
      <View style={styles.container}>
        <StackedBarChart
          style={styles.barchart}
          testID={STRINGS.STACKED_BARCHART_TESTID}
          data={data}
          width={width}
          height={height}
          chartConfig={chartConfig}
          hideLegend={hideLegend}
        />
        <View style={[styles.mainView, legendContainerStyle]}>
          {data?.legend?.map((label, index) => (
            <View key={index} style={[styles.legendView, legendViewStyle]}>
              <View
                style={[
                  styles.legendStyle,
                  { backgroundColor: data?.barColors[index] },
                  legendIconStyle,
                ]}
                testID={STRINGS.LEGEND_COLOR_TESTID}
              />
              <Text style={[styles.legendLabelStyle, legendTextStyle]}>
                {label}
              </Text>
            </View>
          ))}
        </View>
      </View>
    );
  }
}

export default BarCharts;
