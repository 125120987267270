import moment from "moment";
import React, { Component } from "react";
import { Image, TouchableOpacity, View, Text } from "react-native";
import { Calendar } from "react-native-calendars";
import { ICalender, ICalenderState } from "./interface";
import { formatedDate, periodConstructor } from "./utils";
import { styles } from "./styles";
import COLORS from "../../../utils/colors";

class DateRangeCalender extends Component<ICalender, ICalenderState> {
  constructor(props: ICalender) {
    super(props);
    this.state = {
      isCalendarVisible: false,
      fromDate: "",
      toDate: "",
    };
  }

  // ### On day press
  _onDayPress = (dateString: string) => {
    const { markingType } = this.props;
    const { fromDate, toDate } = this.state;
    if (markingType == "period") {
      if (!fromDate || toDate) {
        this.setState({ fromDate: dateString, toDate: "" });
      } else if (fromDate || !toDate) {
        this.setState({ toDate: dateString });
      }
    } else {
      this.setState({ fromDate: dateString, isCalendarVisible: false });
    }
  };

  render() {
    const {
      minDate,
      maxDate,
      markingType,
      markedDates,
      renderHeader,
      renderArrow,
      arrowRtUri = "https://i.imgur.com/zuh7pXB.png",
      arrowLtUri = "https://i.imgur.com/5qQ3eEO.png",
      hidePlaceHolder,
      hidePlaceHolder2,
      placeHolder = "From Date",
      placeHolder2 = "To Date",
      displayDateFormat = "DD MMMM",
      calenderAsPopup = true,
      placeholderContainer,
      calenderContainer,
      iconContainer,
      iconStyle,
      containerStyle,
      theme,
      ...restProps
    } = this.props;
    const momentNow = moment();
    const today = momentNow.format("YYYY-MM-DD");
    const todayCustomStyles = {
      container: styles.todayContainer,
      text: styles.todayText,
    };
    const todayMarker = { [today]: { customStyles: todayCustomStyles } };
    const selectedCustomStyles = {
      container: styles.selectedContainer,
      text: styles.selectedText,
    };
    const selectedMarker = {
      [this.state.fromDate]: {
        customStyles: selectedCustomStyles,
        selected: true,
      },
    };
    const period = periodConstructor(
      this.state.fromDate,
      this.state.toDate,
      todayMarker
    );
    const { isCalendarVisible, fromDate, toDate } = this.state;
    return (
      <>
        <View style={[{ flexDirection: "row" }, containerStyle]}>
          {!hidePlaceHolder ? (
            <TouchableOpacity
              onPress={() =>
                this.setState({ isCalendarVisible: !isCalendarVisible })
              }
              testID="calendarButton"
              style={[styles.placeholderContainer, placeholderContainer]}
            >
              <Text>
                {fromDate
                  ? formatedDate(fromDate, displayDateFormat)
                  : placeHolder}
              </Text>
            </TouchableOpacity>
          ) : null}
          {!hidePlaceHolder2 && markingType == "period" ? (
            <TouchableOpacity
              testID="button2"
              onPress={() =>
                this.setState({ isCalendarVisible: !isCalendarVisible })
              }
              style={[styles.placeholderContainer, placeholderContainer]}
            >
              <Text>
                {toDate
                  ? formatedDate(toDate, displayDateFormat)
                  : placeHolder2}
              </Text>
            </TouchableOpacity>
          ) : null}
        </View>
        {isCalendarVisible ? (
          <Calendar
            // ### Generic
            current={today}
            minDate={minDate}
            maxDate={maxDate}
            markingType={markingType ?? "custom"}
            markedDates={
              markedDates
                ? markedDates
                : markingType == "period"
                ? period
                : selectedMarker
            }
            enableSwipeMonths={true}
            // ### Render
            renderHeader={renderHeader}
            renderArrow={(direction) => {
              return (
                <View style={[styles.iconContainer, iconContainer]}>
                  {direction == "right" ? (
                    <Image
                      source={
                        typeof arrowRtUri === "string"
                          ? { uri: arrowRtUri }
                          : arrowRtUri
                      }
                      style={[styles.iconStyle, iconStyle]}
                    />
                  ) : (
                    <Image
                      source={
                        typeof arrowLtUri === "string"
                          ? { uri: arrowLtUri }
                          : arrowLtUri
                      }
                      style={[styles.iconStyle, iconStyle]}
                    />
                  )}
                </View>
              );
            }}
            // ### OnPress
            onDayPress={(day) => {
              this._onDayPress(day.dateString);
            }}
            // ### Styles
            style={[
              styles.calenderContainer,
              calenderAsPopup && styles.calanderPopUp,
              calenderContainer,
            ]}
            theme={{
              calendarBackground: "transparent",
              textSectionTitleColor: COLORS.COLOR_GRAY,
              todayTextColor: COLORS.COLOR_LIGHTBLACK,
              dayTextColor: COLORS.COLOR_LIGHTBLACK,
              textDisabledColor: COLORS.COLOR_GRAY_PLACEHOLDER,
              textDayStyle: {
                fontSize: 14,
                fontWeight: "400",
              },
              ...theme,
            }}
            {...restProps}
          />
        ) : null}
      </>
    );
  }
}

export default DateRangeCalender;
