import React, { Component } from "react";
import { Image, TouchableOpacity, View, Text } from "react-native";
import styles from "./styles";
import Button from "../Button";
import STRINGS from "../../utils/strings";
import STRINGS_TESTCASE from "../../utils/stringsTestCase";

export interface PopoverProps {
  containerStyle?: {};
  iconStyle?: {};
  titleText?: string;
  titleStyle?: {};
  descriptionText?: string;
  descriptionStyle?: {};
  footerStyle?: {};
  btnStyle?: {};
  txtStyle?: {};
  closeIcon?: string;
  buttonText1?: string;
  buttonText2?: string;
  gradientColor1: string;
  gradientColor2: string;
}

interface PopoverState {
  isModalVisible: boolean;
}

class Popover extends Component<PopoverProps, PopoverState> {
  constructor(props: PopoverProps) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  onPressCloseIcon = () => {};

  onPressButton = () => {};

  render() {
    const {
      containerStyle,
      iconStyle,
      titleText = STRINGS.DEFAULT_TXT_POPOVR_1,
      titleStyle,
      descriptionText = STRINGS.DEFAULT_TXT_POPOVR_2,
      descriptionStyle,
      footerStyle,
      btnStyle,
      txtStyle,
      closeIcon = "https://i.imgur.com/amHs6X5.png",
      buttonText1,
      buttonText2,
      gradientColor1,
      gradientColor2,
      ...props
    } = this.props;
    const { isModalVisible } = this.state;

    return (
      <View style={[styles.innerContainer, containerStyle]}>
        <TouchableOpacity
          onPress={() => this.onPressCloseIcon()}
          testID={STRINGS_TESTCASE.POPOVER_INPUT_COMPONENT_ICON}
          style={styles.iconContainer}
        >
          <Image
            source={
              typeof closeIcon === "string" ? { uri: closeIcon } : closeIcon
            }
            style={[iconStyle, styles.crossIcon]}
          />
        </TouchableOpacity>
        <Text style={[styles.txtTitle, titleStyle]}>{titleText}</Text>
        {/* <View style={styles.horizontalLine} /> */}
        <Text style={[styles.txtDescription, descriptionStyle]}>
          {descriptionText}
        </Text>
        <Text style={[styles.txtFooter, footerStyle]}>{buttonText1}</Text>
        {/* <View style={[height:25]}/> */}
        <View style={styles.buttonStyle}>
          <Button
            theme="primary"
            style={btnStyle}
            text={buttonText2}
            textStyle={[styles.txtDelAccount, txtStyle]}
            onPress={() => this.onPressButton()}
            gradientColor1={gradientColor1}
            gradientColor2={gradientColor2}
          />
        </View>
      </View>
    );
  }
}

export default Popover;
