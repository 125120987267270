import React, { Component } from "react";
import {
  ActivityIndicator,
  TouchableOpacity,
  Image,
  View,
  Text,
  ViewStyle,
  TextStyle,
} from "react-native";
import styles from "./styles";
import LinearGradientComponent from "../LinearGradient";
import COLORS from "../../utils/colors";

export interface ChipsProps {
  state?: "default" | "not active" | "active";
  text?: string;
  style?: ViewStyle;
  textStyle?: TextStyle;
  gradientColor1?: string;
  gradientColor2?: string;
  icon?: string;
  iconStyle?: object;
  iconPosition?: "left" | "right";
  onPress?: () => void;
}

class Chips extends Component<ChipsProps> {
  render() {
    const {
      state = "default",
      text,
      style,
      textStyle,
      gradientColor1 = COLORS.COLOR_MEDIUMBLUE,
      gradientColor2 = COLORS.COLOR_LIGHTBLUE,
      icon,
      iconStyle,
      iconPosition = "left",
      onPress,
      ...props
    } = this.props;

    const defaultChips = () => {
      return (
        <TouchableOpacity {...props}>
          <View
            style={[
              styles.defaultChips,
              { flexDirection: iconPosition == "left" ? "row" : "row-reverse" },
              style,
            ]}
          >
            {icon && (
              <Image
                source={typeof icon === "string" ? { uri: icon } : icon}
                style={[styles.icon, iconStyle]}
              />
            )}
            {text && (
              <Text
                style={[
                  styles.text,
                  { color: COLORS.COLOR_MEDIUMBLUE },
                  textStyle,
                ]}
              >
                {text}
              </Text>
            )}
          </View>
        </TouchableOpacity>
      );
    };

    const notActiveChips = () => {
      return (
        <TouchableOpacity {...props}>
          <View
            style={[
              styles.notActiveChips,
              { flexDirection: iconPosition == "left" ? "row" : "row-reverse" },
              style,
            ]}
          >
            {icon && (
              <Image
                source={typeof icon === "string" ? { uri: icon } : icon}
                style={[styles.icon, iconStyle]}
              />
            )}
            {text && (
              <Text
                style={[
                  styles.text,
                  { color: COLORS.COLOR_DISABLE_GREY },
                  textStyle,
                ]}
              >
                {text}
              </Text>
            )}
          </View>
        </TouchableOpacity>
      );
    };

    const activeChips = () => {
      return (
        <TouchableOpacity {...props}>
          <LinearGradientComponent
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: 32,
              flex: 1,
              ...style,
            }}
            colors={[gradientColor2, gradientColor1]}
          >
            <View
              style={[
                styles.activeChips,
                {
                  flexDirection: iconPosition == "left" ? "row" : "row-reverse",
                },
                // {
                //   backgroundColor: gradientColor1
                //     ? gradientColor1
                //     : COLORS.COLOR_MEDIUMBLUE,
                // },
                style,
              ]}
            >
              {icon && (
                <Image
                  source={typeof icon === "string" ? { uri: icon } : icon}
                  style={[styles.icon, iconStyle]}
                />
              )}
              {text && (
                <Text
                  style={[
                    styles.text,
                    { color: COLORS.COLOR_WHITE },
                    textStyle,
                  ]}
                >
                  {text}
                </Text>
              )}
            </View>
          </LinearGradientComponent>
        </TouchableOpacity>
      );
    };

    return state == "active"
      ? activeChips()
      : state == "not active"
      ? notActiveChips()
      : defaultChips();
  }
}

export default Chips;
