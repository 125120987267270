import React, { Component } from "react";
import {
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
  Text
} from "react-native";
import { horizontalScale, verticalScale } from "../../../utils/screenRatio";

export interface DynamicCellProps {
  rowData: any;
  tableDataContainerStyle?: StyleProp<ViewStyle>;
  tableDataTextStyle?: StyleProp<TextStyle>;
  index: number;
  length: number;
  headerData: Array<string>;
}

class DynamicCell extends Component<DynamicCellProps> {
  render() {
    const {
      rowData,
      tableDataContainerStyle,
      tableDataTextStyle,
      index,
      length,
      headerData,
    } = this.props;

    return (
      <View style={[styles.row, { zIndex: length - index }]}>
        {headerData?.map((ele: string, indexNum: number) => {
          return (
            <View style={[styles.input, tableDataContainerStyle]}>
              {typeof rowData[indexNum] == "string" ? (
                <Text style={[styles.TextStyle, tableDataTextStyle]}>
                  {rowData[indexNum]}
                </Text>
              ) : (
                <>{rowData[indexNum]}</>
              )}
            </View>
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: verticalScale(10),
  },
  row: {
    flexDirection: "row",
    marginVertical: verticalScale(5),
    borderBottomWidth: 0.2,
    borderColor: "#CBD5E1",
    backgroundColor: "white",
  },
  input: {
    flex: 1,
    paddingVertical: verticalScale(5),
    paddingHorizontal: horizontalScale(12),
    justifyContent: "center",
  },
  TextStyle: {
    fontSize: 14,
    color: "#0F172A",
    fontWeight: "400",
    paddingVertical: 8,
  },
});

export default DynamicCell;
