import React, { Component } from "react";
import {
  View,
  StyleProp,
  TextStyle,
  ViewStyle,
  ScrollView,
  Text
} from "react-native";
import DynamicCell from "./component/cell";
import style from "./style";
import getPlatformType from "../../utils/getPlatformType";

export interface DataTableProps {
  tableData: Array<Object>;
  headerData: Array<string>;
  tableTitle: string;
  tableDataContainerStyle?: StyleProp<ViewStyle>;
  headerDataContainerStyle?: StyleProp<ViewStyle>;
  tableDataTextStyle?: StyleProp<TextStyle>;
  headerDataTextStyle?: StyleProp<TextStyle>;
  titleStyle?: StyleProp<TextStyle>;
  ContextMenu?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
}
interface state {
  updatedHeaderData?: any;
}

class DataTable extends Component<DataTableProps, state> {
  constructor(props) {
    super(props);
    this.state = {
      updatedHeaderData: [...this.props.headerData],
    };
    if (this.props.ContextMenu) {
      this.state.updatedHeaderData.push("");
    }
  }

  renderItem = ({ item, index, length }: any) => {
    const {tableDataContainerStyle,tableDataTextStyle} = this.props
    return (
      <DynamicCell
        rowData={item}
        tableDataContainerStyle={tableDataContainerStyle}
        tableDataTextStyle={tableDataTextStyle}
        length={length}
        index={index}
        headerData={this?.state?.updatedHeaderData}
      />
    );
  };

  render() {
    const {tableData,tableTitle,headerDataContainerStyle,headerDataTextStyle,titleStyle, containerStyle} = this.props
    const {updatedHeaderData} = this.state
    return (
      <View style={[style.container, containerStyle]}>
        <View style={[style.header, headerDataContainerStyle]}>
          {updatedHeaderData?.map(
            (headerValue: string, index: number) => (
              <Text
                style={[style.headerText, headerDataTextStyle]}
                key={index}
              >
                {headerValue}
              </Text>
            )
          )}
        </View>
        {tableTitle && (
          <View style={style.textStyle}>
            <Text style={[style.titleStyle, titleStyle]}>
              {tableTitle}
            </Text>
          </View>
        )}
        {getPlatformType() == "phone" ? (
          <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
            <ScrollView>
              {tableData?.map((item, index) => {
                return this.renderItem({
                  item,
                  index,
                  length: tableData.length,
                });
              })}
            </ScrollView>
          </ScrollView>
        ) : (
          tableData?.map((item, index) => {
            return this.renderItem({
              item,
              index,
              length: tableData.length,
            });
          })
        )}
      </View>
    );
  }
}

export default DataTable;
