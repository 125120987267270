import React, { Component } from "react";
import { View, Text, Image } from "react-native";
import { styles } from "./styles";

export interface HeaderProps {
  image?: string;
  imgStyle?: object;
}

class Map extends Component<HeaderProps> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {};
  }
  render() {
    const { image = "https://i.imgur.com/Y7Wu91H.png", imgStyle } = this.props;
    return (
      <View>
        <Image
          source={typeof image === "string" ? { uri: image } : image}
          style={[styles.imgStyle, imgStyle]}
        />
      </View>
    );
  }
}

export default Map;
