import React, { Component } from "react";
import { TouchableOpacity, View, Text } from "react-native";
import style from "../style";
import { RadioButtonMultipleProps, RadioButtonProps } from "../index";
import LinearGradientComponent from "../../../LinearGradient";

interface State {
  radioButtonGroupIndex?: any;
}

class RadioButtonMultiple extends Component<
  RadioButtonMultipleProps & RadioButtonProps,
  State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      radioButtonGroupIndex: undefined,
    };
  }

  renderRadioButtonGroupData = (ele: any, index: any) => {
    const {
      radioButtonStyle,
      radioButtonTextStyle,
      disableRadioButtonTextStyle,
      viewGradientColorStyle,
    } = this.props;
    const { radioButtonGroupIndex } = this.state;

    return (
      <View key={index}>
        {ele?.isDisable ? (
          <View style={style.radioButtonViewStyle}>
            <View
              style={
                ele?.isDisableSelected
                  ? [
                      style.radioButtonDisButtonStyle,
                      style.radioButtonDisSelButtonStyle,
                      radioButtonStyle,
                    ]
                  : [style.radioButtonDisButtonStyle, radioButtonStyle]
              }
            ></View>

            <Text
              style={[
                style.disableRadioButtonTextStyle,
                disableRadioButtonTextStyle,
              ]}
            >
              {ele?.name}
            </Text>
          </View>
        ) : (
          <View style={style.radioButtonViewStyle}>
            <TouchableOpacity
              style={
                index === radioButtonGroupIndex
                  ? [style.radioButtonSelectedButtonStyle, radioButtonStyle]
                  : [style.radioButtonStyle, radioButtonStyle]
              }
              onPress={() => this.setState({ radioButtonGroupIndex: index })}
            >
              {index === radioButtonGroupIndex && (
                <>
                  {viewGradientColorStyle &&
                  viewGradientColorStyle?.length > 0 ? (
                    <LinearGradientComponent colors={viewGradientColorStyle}>
                      <View style={style.linearradioButtonSelectedButtonStyle}>
                        <View style={style.radioButtonInnerView} />
                      </View>
                    </LinearGradientComponent>
                  ) : (
                    <View style={style.radioButtonInnerView} />
                  )}
                </>
              )}
            </TouchableOpacity>

            <Text style={[style.radioButtonTextStyle, radioButtonTextStyle]}>
              {ele?.name}
            </Text>
          </View>
        )}
      </View>
    );
  };

  render() {
    const {
      radioButtonGroupTitle,
      radioButtonGroupData,
      radioButtonGroupTitleStyle,
    } = this.props;

    return (
      <View>
        <Text
          style={[style.radioButtonGroupTitleStyle, radioButtonGroupTitleStyle]}
        >
          {radioButtonGroupTitle}
        </Text>
        {radioButtonGroupData?.map((ele, index) =>
          this.renderRadioButtonGroupData(ele, index)
        )}
      </View>
    );
  }
}

export default RadioButtonMultiple;
