import moment from "moment";
import React, { Component } from "react";
import { View, Image } from "react-native";
import { Calendar } from "react-native-calendars";
import COLORS from "../../utils/colors";
import { ICalender, ICalenderState } from "./interface";
import styles from "./styles";

class CalendarEvent extends Component<ICalender, ICalenderState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedDates: [],
      initialSelDates: [],
      selectedDate: "",
    };
  }

  convertToISODate = (inputDate: any) => {
    const dateObject = moment(inputDate, "D MMMM");
    const formattedDate = dateObject.format("YYYY-MM-DD");
    return formattedDate;
  };

  componentDidMount() {
    const { eventDate } = this.props;

    if (eventDate) {
      const selectedDates = {};
      eventDate.forEach((date) => {
        selectedDates[date] = {
          marked: true,
          dotColor: COLORS.COLOR_HIGHLIGHTBLUE,
        };
      });

      this.setState({ selectedDates });
      this.setState({ initialSelDates: selectedDates });
    }
  }

  // Function to handle date selection
  handleDateSelect = (date) => {
    let markedDates: any = {};
    markedDates[date] = {
      selected: true,
    };
    this.setState({
      selectedDate: date,
      markedDates: markedDates,
    });
    const selected = { ...this.state.initialSelDates, ...markedDates };
    this.setState({ selectedDates: selected });
  };

  render() {
    const {
      calendarStyle,
      arrowRightUrl = "https://i.imgur.com/zuh7pXB.png",
      arrowLeftUrl = "https://i.imgur.com/5qQ3eEO.png",
      theme,
    } = this.props;

    return (
      <View>
        <Calendar
          markedDates={this.state.selectedDates}
          onDayPress={(day) => this.handleDateSelect(day.dateString)}
          style={[styles.calenderStyle, calendarStyle]}
          renderArrow={(direction) => {
            return (
              <View style={styles.renderArrowStyle}>
                {direction == "right" ? (
                  <Image
                    source={
                      typeof arrowRightUrl === "string"
                        ? { uri: arrowRightUrl }
                        : arrowRightUrl
                    }
                    style={styles.imgStyle}
                  />
                ) : (
                  <Image
                    source={
                      typeof arrowLeftUrl === "string"
                        ? { uri: arrowLeftUrl }
                        : arrowLeftUrl
                    }
                    style={styles.imgStyle}
                  />
                )}
              </View>
            );
          }}
          theme={{
            calendarBackground: "transparent",
            selectedDayBackgroundColor: COLORS.COLOR_BLUE,
            selectedDayTextColor: COLORS.COLOR_HIGHLIGHTBLUE,
            textDayFontWeight: "400",
            textDayFontSize: 14,
            textSectionTitleColor: COLORS.COLOR_GRAY,
            todayTextColor: COLORS.COLOR_LIGHTBLACK,
            dayTextColor: COLORS.COLOR_LIGHTBLACK,
            textDisabledColor: COLORS.COLOR_GRAY_PLACEHOLDER,
            monthTextColor: COLORS.COLOR_LIGHTBLACK,
            textMonthFontWeight: "700",
            textMonthFontSize: 14,
            ...theme,
          }}
        />
      </View>
    );
  }
}

export default CalendarEvent;
