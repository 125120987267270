import { StyleSheet } from 'react-native'
import COLORS from '../../utils/colors'

const styles = StyleSheet.create({
  text: {
    alignSelf: 'center',
    fontSize: 16,
    lineHeight: 24,
    fontWeight: '700',
    marginHorizontal: 10,
  },
  icon: {
    width: 20,
    height: 20,
  },
  buttonHovered: {
    justifyContent: 'center', 
    alignItems: 'center',
    borderRadius: 8, 
    backgroundColor: COLORS.COLOR_DARKBLUE,
  },
  buttonLoading: {
    justifyContent: 'center', 
    alignItems: 'center', 
    flexDirection: 'row', 
    borderRadius: 8, 
    backgroundColor: COLORS.COLOR_PRIMARY_PURPLE,
  },
  buttonSecondary: {
    justifyContent: 'center', 
    alignItems: 'center',
    borderRadius: 8,
  },
  buttonDark: {
    justifyContent: 'center', 
    alignItems: 'center',
    borderRadius: 8, 
    backgroundColor: COLORS.COLOR_WHITE,
  },
  buttonDisable: {
    justifyContent: 'center', 
    alignItems: 'center',
    borderRadius: 8, 
    backgroundColor: COLORS.COLOR_BTNGRAY,
  }
});
export default styles;
