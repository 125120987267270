import { StyleSheet } from "react-native";
import COLORS from "../../utils/colors";
const hs = (value: number) => value
const ws = (value: number) => value
const ms = (value: number) => value


export const styles = StyleSheet.create({
    container: {
        height: hs(60),
        width: hs(60),
        borderRadius: 10000,
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        borderWidth: 2,
        borderColor: '#F0E5FF'
    },
    overlay: {
        position: 'absolute',
        backgroundColor: '#00000050',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2
    },
    image: {
        height: '100%',
        width: '100%'
    },
    text: {
        fontSize: ms(24),
        color:COLORS.COLOR_PRIMARY_PURPLE,
        fontWeight:'bold'
    },
    icon: {
        fontSize: ms(18),
    }
})