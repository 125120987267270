import React from "react";
import {
  Image,
  ImageStyle,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  View,
  Text
} from "react-native";
import style from "./style";

export interface FormHeaderProps {
  titleText: string; // title text props 
  iconRight?: string; // right icon url
  detailsText?: string; // detail text 
  iconPress?: () => void; // callback for icon press 
  iconStyle?: object; // icon style 
  titleTextStyle?: StyleProp<TextStyle>; // title text style 
  detailsTextStyle?: StyleProp<TextStyle>; // details text style 
  containerStyle?: object; // main container style 
}

class FormHeader extends React.Component<FormHeaderProps> {
  render() {
    const {
      titleText,
      iconRight,
      detailsText,
      iconPress,
      iconStyle,
      titleTextStyle,
      detailsTextStyle,
      containerStyle,
    } = this.props;

    const iconsStyle = [style.iconStyle, iconStyle];
    const titleTextStyles = [style.titleTextStyle, titleTextStyle];
    const detailsTextStyles = [style.detailsTextStyle, detailsTextStyle];

    return (
      <View style={[style.mainContainer, containerStyle]}>
        <View style={style.subView}>
          <Text style={titleTextStyles}>{titleText}</Text>
          <TouchableOpacity onPress={iconPress}>
            <Image
              source={{ uri: iconRight }}
              style={iconsStyle as ImageStyle}
            />
          </TouchableOpacity>
        </View>
        {detailsText ? (
          <View style={style.detailViewStyle}>
            <Text style={detailsTextStyles}>{detailsText}</Text>
          </View>
        ) : (
          <></>
        )}
      </View>
    );
  }
}

export default React.memo(FormHeader);
