import React, { Component } from "react";
import { StyleProp, ViewStyle } from "react-native";
import ProgressIndicatorCircular from "./component/progressIndicatorCircluar";
import ProgressIndicatorBar from "./component/progressIndicatorBar";

// progress indicator circular
export interface ProgressIndicatorCircularProps {
  progressIndicatorCircular?: true;
  progressIndicatorDarkTheme?: boolean;
  progressDarkThemeMainContainer?: StyleProp<ViewStyle>;
  progressMainContainer?: StyleProp<ViewStyle>;
  progressDarkSpinner?: StyleProp<ViewStyle>;
  progressSpinner?: StyleProp<ViewStyle>;
}

//ProgressindicatorBig
export interface ProgressIndicatorBarProps {
  progressIndicatorCircular?: false;
  indicatorToValue?: number;
  indicatorDuration?: number;
  headerIndicator?: boolean;
  loaderContainer?: StyleProp<ViewStyle>;
  loaderSmallContainer?: StyleProp<ViewStyle>;
  loaderProgress?: StyleProp<ViewStyle>;
}

export interface ProgressIndicatorProps {
  progressIndicatorCircular?: boolean;
}

class ProgressIndicator extends Component<
  ProgressIndicatorProps & (ProgressIndicatorCircularProps | ProgressIndicatorBarProps)
>{
  render() {
    return (
      <>
        {this.props.progressIndicatorCircular == true ? (
          <ProgressIndicatorCircular {...this.props} />
        ) : (
          <ProgressIndicatorBar {...this.props} />
        )}
      </>
    );
  }
};

export default ProgressIndicator;
