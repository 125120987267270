import React, { Component, RefObject } from "react";
import { Image, TouchableOpacity, Platform, View, Text } from "react-native";
import {
  Menu,
  MenuProvider,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import COLORS from "../../utils/colors";
import STRINGS from "../../utils/strings";
import styles from "./styles";
import { optionsStyles, nestedOptionsStyles } from "./styles";
import { View as RnView } from "react-native-web";

interface ContextMenuProps {
  options: {
    id: any;
    label: string;
    leftIcon: string;
    rightIcon: string;
  }[];
  nestedOptions?: { id: any; label: string }[];
  OptionsTextStyle?: {};
  IconName?: string;
  IconLeft?: string;
  IconRight?: string;
  popupPosition?: "left" | "right";
  iconStyle?: object;
  leftIconStyle?: object;
  rightIconStyle?: object;
  menuOptionStyles?: object;
  menuProviderStyle?: object;
  submenuContainerStyle?: object;
  optionsContainerStyle?: object;
}

interface ContextMenuState {
  isMenuOpen: boolean;
  selectedOption: number | null;
  isSubmenuOpen: boolean;
}

export const getColorByText = (label: string): string => {
  switch (label) {
    case "Edit":
      return COLORS.COLOR_COOLGRAY;
    case "Delete":
      return COLORS.COLOR_RED;
    default:
      return COLORS.COLOR_COOLGRAY;
  }
};

export const getBackgroundColor = (
  isSubmenuOpen?: boolean,
  selectedOption?: number,
  option?: any
) => {
  if (
    isSubmenuOpen &&
    selectedOption === option.id &&
    option.rightIcon !== ""
  ) {
    return COLORS.COLOR_LIGHTGRAY;
  } else if (selectedOption === option.id) {
    return COLORS.COLOR_LIGHTPINK;
  } else {
    return "";
  }
};

class ContextMenu extends Component<ContextMenuProps, ContextMenuState> {
  containerRef: RefObject<any>;
  outsideClickListener: (event: Event) => void;

  constructor(props: ContextMenuProps) {
    super(props);

    this.state = {
      isMenuOpen: false,
      selectedOption: null,
      isSubmenuOpen: false,
    };

    this.containerRef = React.createRef<RnView>();
    this.outsideClickListener = this.handlePressOutside.bind(this);
  }

  handleMenuOpen = () => {
    this.setState({ isMenuOpen: true, isSubmenuOpen: false });
  };

  handleMenuClose = () => {
    this.setState({
      isMenuOpen: false,
      isSubmenuOpen: false,
      selectedOption: null,
    });
  };

  handleOptionPress = (option: any) => {
    this.setState({ selectedOption: option.id, isSubmenuOpen: true });
  };

  handleNestedOptionPress = (nestedOption: any) => {
    // Handle nested option press here
  };

  handlePressOutside = (event: any) => {
    if (
      this.containerRef.current &&
      !this.containerRef.current.contains(event.target)
    ) {
      this.handleMenuClose();
    }
  };

  componentDidMount() {
    if (Platform.OS === "web") {
      document.addEventListener("mousedown", this.handlePressOutside);
    }
  }

  componentWillUnmount() {
    if (Platform.OS === "web") {
      document.removeEventListener("mousedown", this.handlePressOutside);
    }
  }

  getPositionStyle = () => {
    const { popupPosition } = this.props;
    return popupPosition === "right"
      ? styles.containerRight
      : styles.containerLeft;
  };

  renderOptions = () => {
    const {
      options,
      nestedOptions,
      OptionsTextStyle,
      leftIconStyle,
      rightIconStyle,
      menuOptionStyles,
      submenuContainerStyle,
    } = this.props;

    return options.map((option) => (
      <MenuOption
        key={option.id}
        style={[
          styles.menuOptionStyle,
          {
            backgroundColor: getBackgroundColor(
              this.state.isSubmenuOpen,
              this.state.selectedOption,
              option
            ),
          },
          menuOptionStyles,
        ]}
      >
        <TouchableOpacity
          style={[styles.optionContainer]}
          onPress={() => this.handleOptionPress(option)}
        >
          <View style={styles.imageView}>
            <View style={{ width: 6 }}>
              {option.leftIcon !== "" && (
                <Image
                  resizeMode={"contain"}
                  style={[styles.leftIcon, leftIconStyle]}
                  source={{
                    uri: this.props.IconLeft
                      ? this.props.IconLeft
                      : option?.leftIcon,
                  }}
                />
              )}
            </View>
            <Text
              style={[
                styles.optionText,
                { color: getColorByText(option.label) },
                OptionsTextStyle,
              ]}
            >
              {option?.label}
            </Text>
          </View>
          {option.rightIcon !== "" && (
            <Image
              resizeMode={"contain"}
              style={[styles.rightIcon, rightIconStyle]}
              source={{
                uri: this.props.IconRight
                  ? this.props.IconRight
                  : option?.rightIcon,
              }}
            />
          )}
        </TouchableOpacity>
        {option.rightIcon !== "" &&
          this.state.selectedOption === option.id &&
          this.state.isSubmenuOpen && (
            <View style={[styles.submenuContainer, submenuContainerStyle]}>
              <MenuOptions customStyles={nestedOptionsStyles}>
                {nestedOptions?.map((nestedOption) => (
                  <MenuOption key={nestedOption.id}>
                    <TouchableOpacity
                      style={styles.optionContainer}
                      onPress={() => this.handleNestedOptionPress(nestedOption)}
                    >
                      <Text style={[styles.optionText, OptionsTextStyle]}>
                        {nestedOption.label}
                      </Text>
                    </TouchableOpacity>
                  </MenuOption>
                ))}
              </MenuOptions>
            </View>
          )}
      </MenuOption>
    ));
  };

  render() {
    const { isMenuOpen } = this.state;

    return (
      <View style={styles.container}>
        <TouchableOpacity testID={STRINGS.CONTEXTMENU_TESTID}>
          <MenuProvider
            skipInstanceCheck
            style={[styles.menuProviderStyles, this.props.menuProviderStyle]}
          >
            <Menu
              //testID="menu"
              onOpen={this.handleMenuOpen}
              onClose={this.handleMenuClose}
              opened={isMenuOpen}
            >
              <MenuTrigger
                onPress={() => this.setState({ isMenuOpen: !isMenuOpen })}
              >
                <View
                  style={[
                    styles.highlightedIconView,
                    {
                      backgroundColor: isMenuOpen
                        ? COLORS.COLOR_LIGHTGRAY
                        : COLORS.COLOR_WHITE,
                    },
                  ]}
                >
                  <Image
                    resizeMode={"contain"}
                    style={[
                      styles.icon,
                      isMenuOpen && styles.highlightedIcon,
                      this.props.iconStyle,
                    ]}
                    source={{
                      uri: this.props.IconName
                        ? this.props.IconName
                        : "https://i.imgur.com/fEsFjVT.png",
                    }}
                  />
                </View>
              </MenuTrigger>
              {!isMenuOpen ? null : (
                <View
                  ref={this.containerRef}
                  style={[
                    this.getPositionStyle(),
                    optionsStyles.optionsContainer,
                    this.props.optionsContainerStyle,
                  ]}
                >
                  <MenuOptions>{this.renderOptions()}</MenuOptions>
                </View>
              )}
            </Menu>
          </MenuProvider>
        </TouchableOpacity>
      </View>
    );
  }
}

export default ContextMenu;
