import React, { Component } from "react";
import { Image, TouchableOpacity, View, Text } from "react-native";
import style from "../style";
import { CheckBoxMultipleProps, CheckBoxProps } from "..";
import LinearGradientComponent from "../../../LinearGradient";

interface CheckBoxMultipleState {
  checkBoxGroupSelectedData?: any;
}

class CheckBoxMultiple extends Component<
  CheckBoxMultipleProps & CheckBoxProps,
  CheckBoxMultipleState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      checkBoxGroupSelectedData: [],
    };
  }

  setCheckBoxGroupSelect = (index: any) => {
    const { checkBoxGroupSelectedData } = this.state;
    const existingData = [...checkBoxGroupSelectedData];
    const existInd = existingData.findIndex((item) => item === index);
    if (existInd === -1) {
      this.setState((prevState) => ({
        checkBoxGroupSelectedData: [
          ...prevState.checkBoxGroupSelectedData,
          index,
        ],
      }));
    } else {
      this.setState((prevState) => ({
        checkBoxGroupSelectedData: prevState.checkBoxGroupSelectedData.filter(
          (item: any) => item !== index
        ),
      }));
    }
  };

  renderCheckBoxGroupData = (ele: any, index: any) => {
    const { checkBoxGroupSelectedData } = this?.state;
    const {
      checkBoxButtonStyle,
      checkBoxDisableButtonStyle,
      checkBoxDisableSelectedButtonStyle,
      checkBoxTextStyle,
      disableCheckBoxTextStyle,
      rightClickIcon,
      viewGradientColorStyle,
      checkBoxViewStyle,
    } = this?.props;
    return (
      <View key={index}>
        {ele?.isDisable ? (
          <View style={[style.checkBoxViewStyle, checkBoxViewStyle]}>
            <View
              style={
                ele?.disableCheckBoxSelected
                  ? [
                      style.checkBoxDisableSelectedButtonStyle,
                      checkBoxDisableSelectedButtonStyle,
                    ]
                  : [
                      style.checkBoxDisableButtonStyle,
                      checkBoxDisableButtonStyle,
                    ]
              }
            >
              {ele?.disableCheckBoxSelected && (
                <Image
                  source={
                    typeof rightClickIcon === "string"
                      ? { uri: rightClickIcon }
                      : rightClickIcon
                  }
                  style={style.symbol}
                />
              )}
            </View>
            <Text
              style={[style.disableCheckBoxTextStyle, disableCheckBoxTextStyle]}
            >
              {ele.name}
            </Text>
          </View>
        ) : (
          <View style={[style.checkBoxViewStyle, checkBoxViewStyle]}>
            <TouchableOpacity
              style={
                checkBoxGroupSelectedData.includes(index)
                  ? [style.checkBoxSelectedButtonStyle, checkBoxButtonStyle]
                  : [style.checkBoxButtonStyle, checkBoxButtonStyle]
              }
              onPress={() => this.setCheckBoxGroupSelect(index)}
            >
              {checkBoxGroupSelectedData.includes(index) && (
                <>
                  {viewGradientColorStyle &&
                  viewGradientColorStyle?.length > 0 ? (
                    <>
                      {checkBoxGroupSelectedData.includes(index) ? (
                        <LinearGradientComponent
                          colors={viewGradientColorStyle}
                          style={{ borderRadius: 5 }}
                        >
                          <View style={style.linearcheckBoxSelectedButtonStyle}>
                            <Image
                              source={
                                typeof rightClickIcon === "string"
                                  ? { uri: rightClickIcon }
                                  : rightClickIcon
                              }
                              style={style.symbol}
                            />
                          </View>
                        </LinearGradientComponent>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      {checkBoxGroupSelectedData.includes(index) && (
                        <Image
                          source={
                            typeof rightClickIcon === "string"
                              ? { uri: rightClickIcon }
                              : rightClickIcon
                          }
                          style={style.symbol}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </TouchableOpacity>

            <Text style={[style.checkBoxTextStyle, checkBoxTextStyle]}>
              {ele.name}
            </Text>
          </View>
        )}
      </View>
    );
  };

  render() {
    const { checkBoxGroupData, checkBoxGroupTitle, checkBoxGroupTitleStyle } =
      this?.props;
    return (
      <View>
        <Text style={[style.checkBoxGroupTitleStyle, checkBoxGroupTitleStyle]}>
          {checkBoxGroupTitle}
        </Text>
        {checkBoxGroupData?.map((ele: any, index: any) =>
          this.renderCheckBoxGroupData(ele, index)
        )}
      </View>
    );
  }
}

export default CheckBoxMultiple;
