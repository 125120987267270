import React, { Component } from "react";
import { Image, TouchableOpacity, View, Text } from "react-native";
import styles from "./styles";
import Avatar from "../Avatar";
import STRINGS from "../../utils/strings";

export interface ToastProps {
  toastMsg: string;
  leftIcon?: string;
  avatarImg?: string;
  buttonText?: string;
  subText?: string;
  rightIcon?: string;
  textViewStyle?: object;
  titleTextStyle?: object;
  detailTextStyle?: object;
  containerStyle?: object;
  leftIconStyle?: object;
  toastMsgStyle?: object;
  btnTextStyle?: object;
  btnStyle?: object;
  rightIconStyle?: object;
  avatarStyle?: object;
  maxlengthSubText?: number;
  maxlengthTitleText?: number;
  subTextViewStyle?: object;
  onClickAvatar?: () => void;
  onPressRightIcon?: () => void;
  onPressBtn?: () => void;
}

class Toast extends Component<ToastProps> {
  render() {
    const {
      toastMsg,
      leftIcon,
      avatarImg,
      buttonText,
      subText,
      rightIcon,
      rightIconStyle,
      containerStyle,
      leftIconStyle,
      toastMsgStyle,
      btnTextStyle,
      btnStyle,
      textViewStyle,
      titleTextStyle,
      detailTextStyle,
      avatarStyle,
      maxlengthSubText = 20,
      maxlengthTitleText = 20,
      subTextViewStyle,
      onClickAvatar,
      onPressRightIcon,
      onPressBtn,
    } = this.props;

    return (
      <View style={[styles.subView, containerStyle]}>
        <View
          style={{
            alignItems: "center",
          }}
        >
          {leftIcon ? (
            <Image
              source={
                typeof leftIcon === "string" ? { uri: leftIcon } : leftIcon
              }
              resizeMode={"contain"}
              style={[styles.leftIconStyle, leftIconStyle]}
            />
          ) : avatarImg ? (
            <Avatar
              testIDContainer={STRINGS.TOAST_AVATAR}
              image={avatarImg}
              edit={false}
              onPress={() => onClickAvatar && onClickAvatar()}
              size={32}
              style={[styles.avatarStyle, avatarStyle]}
            />
          ) : null}
        </View>

        <View style={[styles.textView, textViewStyle]}>
          {!subText && (
            <Text style={[styles.msgStyle, toastMsgStyle]}>
              {" "}
              {toastMsg.length < maxlengthTitleText
                ? `${toastMsg}`
                : `${toastMsg.substring(0, maxlengthTitleText)}...`}
            </Text>
          )}
          {subText && (
            <View style={[styles.subTextView, subTextViewStyle]}>
              <Text style={[styles.text, titleTextStyle]}>{toastMsg}</Text>
              <Text style={[styles.subText, detailTextStyle]}>
                {subText.length < maxlengthSubText
                  ? `${subText}`
                  : `${subText.substring(0, maxlengthSubText)}...`}
              </Text>
            </View>
          )}
        </View>
        <>
          {buttonText ? (
            <TouchableOpacity
              style={[styles.btnStyle, btnStyle]}
              testID={STRINGS.TOAST_BTN}
              onPress={() => onPressBtn && onPressBtn()}
            >
              <Text style={[styles.btnTextStyle, btnTextStyle]}>
                {buttonText}
              </Text>
            </TouchableOpacity>
          ) : rightIcon ? (
            <TouchableOpacity
              testID={STRINGS.TOAST_CLOSE}
              onPress={() => onPressRightIcon && onPressRightIcon()}
              style={{
                alignSelf: "flex-start",
                bottom: 5,
              }}
            >
              <Image
                source={
                  typeof rightIcon === "string" ? { uri: rightIcon } : rightIcon
                }
                resizeMode={"contain"}
                style={[styles.rightIconStyle, rightIconStyle]}
              />
            </TouchableOpacity>
          ) : null}
        </>
      </View>
    );
  }
}

export default Toast;
