import React, { Component } from "react";
import { Image, View, TouchableOpacity, Text } from "react-native";
import { styles } from "./styles";
import { IAvatar } from "./interface";

interface AvatarProps extends IAvatar {}

interface AvatarState {
  hover: boolean;
}

class Avatar extends Component<AvatarProps, AvatarState> {
  constructor(props: AvatarProps) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  renderContent = () => {
    const { image, name, textStyle, size } = this.props;
    const { hover } = this.state;
    const { testIDImage } = this.props;
    let font_size = size ? Number(size) / 3 : 0;
    if (image) {
      return (
        <Image
          source={typeof image === "string" ? { uri: image } : image}
          style={[styles.image]}
          resizeMode={"stretch"}
          testID={this.props.testIDImage}
        />
      );
    } else if (name) {
      return (
        <Text
          style={[styles.text, textStyle, { fontSize: font_size }]}
          testID={this.props.testIDText}
        >
          {name.charAt(0)}
        </Text>
      );
    } else {
      return null;
    }
  };

  render() {
    const {
      edit,
      onPress,
      size,
      backgroundColor,
      style,
      testIDContainer,
      testIDOverlay,
      editIcon,
      editText = "Edit Picture",
    } = this.props;
    const { hover } = this.state;
    const _backgroundColor = backgroundColor
      ? { backgroundColor: backgroundColor }
      : {};
    const _size = size ? { height: Number(size), width: Number(size) } : {};
    let mainSize: any = size;
    let font_size = mainSize / 3;

    return (
      <TouchableOpacity
        onPress={onPress}
        style={[styles.container, _backgroundColor, style, _size]}
        testID={testIDContainer}
        // onHoverIn={() => edit && this.setState({ hover: true })}
        // onHoverOut={() => this.setState({ hover: false })}
      >
        {this.renderContent()}
        {(edit || hover) && (
          <View style={[styles.overlay]} testID={testIDOverlay}>
            <Image
              source={
                typeof editIcon === "string"
                  ? {
                      uri: editIcon
                        ? editIcon
                        : "https://i.imgur.com/aD42TN5.png",
                    }
                  : editIcon
              }
              style={{
                height: font_size / 3,
                width: font_size / 3,
                marginBottom: 4,
              }}
            />

            <Text style={{ fontSize: font_size / 3, color: "white" }}>
              {editText}
            </Text>
          </View>
        )}
      </TouchableOpacity>
    );
  }
}

export default Avatar;
