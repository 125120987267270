import React, { Component } from "react";
import {
  FlatList,
  ScrollView,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  Text

} from "react-native";
import style from "./style";

export interface SideMenuListProps {
  mainTitle?: string;
  sideBarContainer?: StyleProp<ViewStyle>;
  sideBartitleStyle?: StyleProp<TextStyle>;
  listData?: Array<Object>;
  listSubTitlePressStyle?: StyleProp<TextStyle>;
  listSubTitleStyle?: StyleProp<TextStyle>;
  listSubtTitleButtonStyle?: Object;
  listSubtTitleButtonTouchStyle?: Object;
  listSubViewTitleTouchStyle?: Object;
  listSubViewTitleStyle?: Object;
  listSubtTitleButtonPressStyle?: Object;
  listSubViewTitleButtonStyle?: Object;
  listSubActiveIndex?: number;
}

interface state {
  subListId: any;
  subListTabId: any;
}
class SideMenuList extends Component<SideMenuListProps, state> {
  constructor(props: any) {
    super(props);
    this.state = {
      subListId: this?.props?.listSubActiveIndex,
      subListTabId: 0,
    };
  }


  updaterenderSubState(index: any) {
    this.setState({
      ...this.state,
      subListTabId: index,
    });
  }

  renderSubSideMenuData = (item: any, index: any) => {
    const {listSubViewTitleStyle, listSubViewTitleTouchStyle, listSubtTitleButtonStyle, listSubtTitleButtonTouchStyle} =  this?.props
    const {subListTabId} = this?.state

    return (
      <TouchableOpacity
        style={
          subListTabId == index
            ? [
              style.listSubtTitleButtonTouchStyle,
             listSubtTitleButtonTouchStyle,
            ]
            : [
              style.listSubtTitleButtonStyle,
              listSubtTitleButtonStyle,
            ]
        }
        onPress={() => this.updaterenderSubState(index)}
      >
        <Text
          style={
            subListTabId == index
              ? [
                style.listSubViewTitleTouchStyle,
                listSubViewTitleTouchStyle,
              ]
              : [style.listSubViewTitleStyle, listSubViewTitleStyle]
          }
        >
          {item}
        </Text>
      </TouchableOpacity>
    );
  };

  updaterenderSideMenuData(id: any) {
    this.setState(
      {
        ...this.state,
        subListId: id,
      });
  }
  renderSideMenuData = ({ item }: any) => {
    const {listSubTitlePressStyle, listSubTitleStyle, listSubViewTitleButtonStyle, listSubtTitleButtonPressStyle} =  this?.props
    const {subListId} = this?.state
    return (
      <>
        <TouchableOpacity
          style={
        subListId == item?.id
              ? [
                style.listSubtTitleButtonPressStyle,
                listSubtTitleButtonPressStyle,
              ]
              : [
                style.listSubViewTitleButtonStyle,
               listSubViewTitleButtonStyle,
              ]
          }
          onPress={() => this.updaterenderSideMenuData(item?.id)}
        >
          <Text
            style={
              subListId == item?.id
                ? [
                  style.listSubTitlePressStyle,
                 listSubTitlePressStyle,
                ]
                : [style.listSubTitleStyle,listSubTitleStyle]
            }
          >
            {item?.title}
          </Text>
        </TouchableOpacity>
        {
         subListId == item?.id &&
          item?.data?.length > 0 && (
            <View style={style.subViewStyle}>
              <ScrollView
                nestedScrollEnabled={true}
                showsVerticalScrollIndicator={false}
              >
                {item?.data?.map((item: any, index: any) => {
                  return this.renderSubSideMenuData(item, index)
                })}

              </ScrollView>
            </View>
          )}
      </>
    );
  };

  render() {
    const {listData, sideBarContainer,sideBartitleStyle} =  this?.props
    const {subListId,subListTabId} = this?.state
    return (
      <View style={style.mainView}>
        <View style={[style.sideBarContainer, sideBarContainer]}>
          <Text
            style={[style.sideBartitleStyle, sideBartitleStyle]}
          >
            {this.props.mainTitle}
          </Text>
          <View>
            <FlatList
              data={listData as Array<Object>}
              keyExtractor={(item) => item.toString()}
              renderItem={(item: any) => this.renderSideMenuData(item)}
              extraData={[subListId, subListTabId]}
            />
          </View>
        </View>
      </View>
    );
  }
}

export default SideMenuList;
