import moment from "moment";
import React, { Component } from "react";
import { View, TextInput, Image } from "react-native";
import { Calendar } from "react-native-calendars";
import COLORS from "../../utils/colors";
import { ICalender, ICalenderState } from "./interface";
import styles from "./styles";

class CalendarInput extends Component<ICalender, ICalenderState> {
  constructor(props: ICalender) {
    super(props);
    this.state = {
      isCalendarVisible: false,
      selectedDate: "",
      markedDates: {},
    };
  }

  componentDidMount = () => {
    let markedDates: any = {};
    const { currentDate } = this.props;
    let date: any = currentDate
      ? this.convertToISODate(currentDate)
      : this.convertToISODate(new Date());
    markedDates[date] = {
      selected: true,
    };
    this.setState({
      selectedDate: currentDate ? currentDate : this.formatDate(new Date()),
      markedDates: markedDates,
    });
  };

  convertToISODate = (inputDate: any) => {
    const dateObject = moment(inputDate, "D MMMM");
    const formattedDate = dateObject.format("YYYY-MM-DD");
    return formattedDate;
  };

  handleToggleCalendar = () => {
    this.setState((prevState) => ({
      isCalendarVisible: !prevState.isCalendarVisible,
    }));
  };

  handleDateSelect = (date: any) => {
    let markedDates: any = {};
    markedDates[date] = {
      selected: true,
    };
    this.setState({
      selectedDate: this.formatDate(date),
      markedDates: markedDates,
    });
  };

  formatDate = (date: any) => {
    const selectedDate = new Date(date);
    const day = selectedDate.getDate();
    const month = selectedDate.toLocaleString("default", { month: "long" });

    const formattedDate = `${day} ${month}`;
    return formattedDate;
  };

  render() {
    const {
      calendarStyle,
      currentDate,
      calendarTextInputStyle,
      arrowRightUrl = "https://i.imgur.com/zuh7pXB.png",
      arrowLeftUrl = "https://i.imgur.com/5qQ3eEO.png",
      theme,
      leftIconUrl,
      leftIconStyle,
    } = this.props;
    const { isCalendarVisible } = this.state;

    var dateObject = new Date(currentDate + " " + new Date().getFullYear());
    var todayDate = new Date();

    return (
      <View>
        <View style={[styles.container]}>
          {leftIconUrl && (
            <Image
              source={
                typeof leftIconUrl === "string"
                  ? { uri: leftIconUrl }
                  : leftIconUrl
              }
              resizeMode={"contain"}
              style={[styles.leftIcon, leftIconStyle]}
            />
          )}
          <TextInput
            placeholder={"select Date"}
            onFocus={this.handleToggleCalendar}
            onBlur={this.handleToggleCalendar}
            value={this.state.selectedDate}
            placeholderTextColor={COLORS.COLOR_LIGHTBLACK}
            style={[styles.textInputStyle, calendarTextInputStyle]}
          />
        </View>

        {isCalendarVisible && (
          <Calendar
            current={
              currentDate
                ? dateObject.toISOString().split("T")[0]
                : todayDate.toISOString().split("T")[0]
            }
            markedDates={this.state.markedDates}
            style={[styles.calenderStyle, calendarStyle]}
            renderArrow={(direction) => {
              return (
                <View style={styles.renderArrowStyle}>
                  {direction == "right" ? (
                    <Image
                      source={
                        typeof arrowRightUrl === "string"
                          ? { uri: arrowRightUrl }
                          : arrowRightUrl
                      }
                      style={styles.imgStyle}
                    />
                  ) : (
                    <Image
                      source={
                        typeof arrowLeftUrl === "string"
                          ? { uri: arrowLeftUrl }
                          : arrowLeftUrl
                      }
                      style={styles.imgStyle}
                    />
                  )}
                </View>
              );
            }}
            onDayPress={(day: any) => {
              this.handleDateSelect(day.dateString);
            }}
            theme={{
              calendarBackground: "transparent",
              selectedDayBackgroundColor: COLORS.COLOR_BLUE,
              selectedDayTextColor: COLORS.COLOR_HIGHLIGHTBLUE,
              textDayFontWeight: "400",
              textDayFontSize: 14,
              textSectionTitleColor: COLORS.COLOR_GRAY,
              todayTextColor: COLORS.COLOR_BLACK,
              dayTextColor: COLORS.COLOR_LIGHTBLACK,
              textDisabledColor: COLORS.COLOR_GRAY_PLACEHOLDER,
              monthTextColor: COLORS.COLOR_LIGHTBLACK,
              textMonthFontWeight: "700",
              textMonthFontSize: 14,
              ...theme,
            }}
          />
        )}
      </View>
    );
  }
}

export default CalendarInput;
