import React, { Component } from "react";
import { View, Text } from "react-native";
import STRINGS from "../../../utils/strings";
import { styles } from "./styles";
import { LineChart } from "../../../modules/react-native-chart-kit/src";

interface Dataset {
  data: number[];
  strokeWidth: number;
  strokeColor: string;
  fill?: string;
  legendLabel: string;
}

interface SplineAreaChartProps {
  data: {
    labels: string[];
    datasets: Dataset[];
  };
  chartConfig: {
    backgroundGradientFrom: string;
    backgroundGradientTo: string;
    decimalPlaces: number;
    useShadowColorFromDataset: boolean;
    color: (opacity: number) => string;
    propsForDots: {
      r: string;
      strokeWidth: string;
    };
    propsForBackgroundLines: {
      strokeDasharray: string;
    };
  };
  showGridLines: boolean;
  height: number;
  width: number;
  containerStyle?: Object;
  legendContainerStyle?: Object;
  legendIconStyle?: Object;
  legendLabelStyle?: Object;
  ySpacing?: number;
}

class SplineAreaChart extends Component<SplineAreaChartProps> {
  constructor(props: SplineAreaChartProps) {
    super(props);
    // Initialize state or perform any other setup if needed
  }

  render() {
    const {
      data,
      chartConfig,
      showGridLines,
      width,
      height,
      legendIconStyle,
      legendLabelStyle,
      ySpacing = 30,
      containerStyle,
      legendContainerStyle,
    } = this.props;

    const { labels, datasets } = data;

    const chartData = {
      labels,
      datasets: datasets.map((dataset) => ({
        data: dataset.data,
        strokeWidth: dataset.strokeWidth,
        color: () => dataset.strokeColor, // Set the stroke color dynamically
        fill: dataset.fill,
      })),
    };

    const updatedChartConfig = {
      ...chartConfig,
      labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`, // Set the label color to black
      propsForYLabels: {
        dy: ySpacing, // Adjust the spacing between y-axis values
      },
    };

    return (
      <View>
        <View
          style={[styles.container, containerStyle]}
          testID={STRINGS.SPLINE_CHART_TESTID}
        >
          <LineChart
            data={chartData}
            width={width}
            height={height}
            chartConfig={updatedChartConfig}
            bezier
            withHorizontalLines={true}
            withInnerLines={showGridLines}
            withVerticalLines={false}
          />
        </View>
        <View style={[styles.legendContainer, legendContainerStyle]}>
          {datasets.map((dataset, index) =>
            dataset.legendLabel == "" ? null : (
              <View key={index} style={styles.legendItem}>
                <View
                  style={[
                    styles.legendIcon,
                    { backgroundColor: dataset.strokeColor },
                    legendIconStyle,
                  ]}
                />
                <Text style={[styles.legendLabel, legendLabelStyle]}>
                  {dataset.legendLabel}
                </Text>
              </View>
            )
          )}
        </View>
      </View>
    );
  }
}

export default SplineAreaChart;
