import React, { Component } from "react";
import { View, Text, TouchableOpacity, FlatList, Image } from "react-native";
import {
  IAccordion,
  IAccordionItem,
  IAccordionItemState,
  IAccordionState,
} from "./interface";
import { styles } from "./styles";

class Accordion extends Component<IAccordion, IAccordionState> {
  constructor(props: IAccordion) {
    super(props);
    this.state = {
      data: props.accordiondata,
    };
  }

  render() {
    const {
      accordiondata,
      containerStyle,
      containerHeight,
      containerWidth,
      topArrow,
      bottomArrow,
      iconStyle,
      titleStyle,
      subTitleStyle,
      ...restProps
    } = this.props;
    const { data } = this.state;
    const height = containerHeight ? { height: containerHeight } : {};
    const width = containerWidth ? { width: containerWidth } : {};
    return (
      <View style={[styles.containerStyle, height, width, containerStyle]}>
        <FlatList
          data={data}
          keyExtractor={(item, index) => `${index}`}
          renderItem={({ item, index }) => {
            return (
              <RenderAccordionItem
                {...restProps}
                item={item}
                index={index}
                topArrow={topArrow}
                bottomArrow={bottomArrow}
              />
            );
          }}
        />
      </View>
    );
  }
}

export class RenderAccordionItem extends Component<
  IAccordionItem,
  IAccordionItemState
> {
  constructor(props: IAccordion) {
    super(props);
    this.state = {
      isVisible: props.isAccordionVisible || props.item.isAccordionVisible,
    };
  }

  onPressHeader = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  RenderAccordionHeader = () => {
    const {
      item,
      headerContainer,
      bottomArrow,
      topArrow,
      iconStyle,
      titleStyle,
      subTitleStyle,
    } = this.props;
    const { isVisible } = this.state;
    return (
      <TouchableOpacity
        onPress={this.onPressHeader}
        style={[styles.headerContainer, headerContainer]}
      >
        <View style={{ flexDirection: "row", paddingRight: 8 }}>
          <Text style={[styles.title, titleStyle]}>{item.title}</Text>
          <View style={[styles.iconStyle, iconStyle]}>
            {!isVisible ? (
              <Image
                source={
                  typeof bottomArrow === "string"
                    ? { uri: bottomArrow }
                    : bottomArrow
                }
                style={styles.imageStyle}
              />
            ) : (
              <Image
                source={
                  typeof topArrow === "string" ? { uri: topArrow } : topArrow
                }
                style={styles.imageStyle}
              />
            )}
          </View>
        </View>
        {item.subTitle ? (
          <Text style={[styles.subTitle, subTitleStyle]}>{item.subTitle}</Text>
        ) : null}
      </TouchableOpacity>
    );
  };

  RenderAccordionContent = () => {
    const { renderAccordionContent, contentContainer } = this.props;
    const { isVisible } = this.state;
    if (!isVisible) return null;
    else if (renderAccordionContent) return renderAccordionContent();
    else
      return (
        <View style={[styles.contentContainer, contentContainer]}>
          <View style={styles.sampleContent} />
        </View>
      );
  };

  render() {
    const { item, index, renderAccordionHeader, itemContainer } = this.props;
    return (
      <View style={[styles.itemContainer, itemContainer]} key={index}>
        {renderAccordionHeader
          ? renderAccordionHeader()
          : this.RenderAccordionHeader()}
        {this.RenderAccordionContent()}
      </View>
    );
  }
}
export default Accordion;
