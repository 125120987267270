import { StyleSheet } from "react-native";
import {
  horizontalScale,
  moderateScale,
  verticalScale,
} from "../../../utils/screenRatio";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  mainView: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: verticalScale(12),
    // marginHorizontal: horizontalScale(70),
  },
  barchart: {
    borderRadius: 25,
  },
  legendView: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: horizontalScale(10),
  },
  legendStyle: {
    marginRight: horizontalScale(3),
    borderRadius: moderateScale(25),
    width: 10,
    height: 10,
  },
  legendLabelStyle: {
    fontSize: moderateScale(7),
    fontWeight: "400",
    // fontFamily:"Inter-Regular",
  },
});
