import React, { Component } from "react";
import {
  SafeAreaView,
  View,
  FlatList,
  TouchableOpacity,
  Image,
  Text,
} from "react-native";
import styles from "./styles";
import COLORS from "../../utils/colors";
import Toggle from "../Selectors/Toggle";

export interface BottomTabProps {
  listData: Array<object>;
  containerStyle?: object;
  leftIconStyle?: object;
  textViewStyle?: object;
  titleTextStyle?: object;
  detailTextStyle?: object;
  rightIconStyle?: object;
  switchView?: object;
  switchStyle?: object;
  flatListView?: object;
}

export interface BottonTabInterface {
  isEnabled: boolean;
}

class ListItem extends Component<BottomTabProps, BottonTabInterface> {
  constructor(props) {
    super(props);
    this.state = {
      isEnabled: false,
    };
  }

  toggleSwitch = () => {
    this.setState((prevState) => ({
      isEnabled: !prevState.isEnabled,
    }));
  };

  ItemDivider = () => {
    return <View style={styles.itemSeparator} />;
  };

  renderItem = ({ item }: any) => {
    const {
      containerStyle,
      leftIconStyle,
      textViewStyle,
      titleTextStyle,
      detailTextStyle,
      rightIconStyle,
      switchView,
      switchStyle,
    } = this.props;

    return (
      <TouchableOpacity style={[styles.container, containerStyle]}>
        <Image
          source={
            typeof item?.iconLeft === "string"
              ? { uri: item?.iconLeft }
              : item?.iconLeft
          }
          resizeMode={"contain"}
          style={[styles.icon, leftIconStyle]}
        />
        <View style={[styles.textView, textViewStyle]}>
          <Text style={[styles.text, titleTextStyle]}>{item?.title}</Text>
          <Text style={[styles.subText, detailTextStyle]}>{item?.details}</Text>
        </View>
        <Image
          source={
            typeof item?.iconRight === "string"
              ? { uri: item?.iconRight }
              : item?.iconRight
          }
          resizeMode={"contain"}
          style={[styles.rightIcon, rightIconStyle]}
        />
        {item?.children}
        <View style={[styles.switch, switchView]}>
          {item?.switch && (
            <Toggle
              thumbColor={COLORS.COLOR_WHITE}
              trackContainerStyle={[styles.switchStyle, switchStyle]}
            />
          )}
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    const { listData, flatListView } = this.props;

    return (
      <SafeAreaView>
        <View style={flatListView}>
          <FlatList
            data={listData}
            keyExtractor={(item, index) => index.toString()}
            renderItem={this.renderItem}
            ItemSeparatorComponent={this.ItemDivider}
          />
        </View>
      </SafeAreaView>
    );
  }
}

export default ListItem;
