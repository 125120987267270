import { StyleSheet } from "react-native";
import COLORS from "../../../utils/colors";
import {
  horizontalScale,
  moderateScale,
  verticalScale,
} from "../../../utils/screenRatio";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  chart: {
    height: verticalScale(250),
    width: 150,
  },
  legendContainer: {
    marginLeft: horizontalScale(2),
    // lineHeight: verticalScale(1),
  },
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: horizontalScale(10),
    padding: 3,
  },
  legendIcon: {
    width: 10,
    height: 10,
    borderRadius: moderateScale(5),
    marginRight: horizontalScale(5),
  },
  labelValue: {
    color: COLORS.COLOR_COOLGRAY,
    fontSize: moderateScale(7),
    // fontFamily: "Inter-Bold",
    fontWeight: "700",
  },
  labelName: {
    color: COLORS.COLOR_COOLGRAY,
    fontSize: moderateScale(7),
    // fontFamily: "Inter-Regular",
    fontWeight: "400",
  },
  symbol: {
    paddingLeft: 5,
    color: COLORS.COLOR_COOLGRAY,
    fontSize: moderateScale(7),
    // fontFamily: "Inter-Bold",
    fontWeight: "700",
  },
});
