import React, { Component } from "react";
import { View, Text } from "react-native";
import { PieChart, PieChartData } from "react-native-svg-charts";
import STRINGS from "../../../utils/strings";
import { styles } from "./styles";

interface DataItem extends PieChartData {
  key: string;
  label: string;
  value: number;
  svg?: {
    fill: string;
  };
}

interface PieChartsProps {
  data: DataItem[];
  containerStyle?: Object;
  styleChart?: Object;
  legendIconStyle?: Object;
  legendItemStyle?: Object;
  labelNameStyle?: Object;
  labelValueStyle?: Object;
  prefix?: string;
  prefixStyle?: Object;
}

class PieCharts extends Component<PieChartsProps> {
  constructor(props: PieChartsProps) {
    super(props);
  }

  renderLegend() {
    const {
      data,
      legendIconStyle,
      legendItemStyle,
      labelNameStyle,
      labelValueStyle,
      prefix,
      prefixStyle,
    } = this.props;

    const formatViews = (value: any) => {
      return value.toLocaleString();
    };

    return data.map((legend, index) => (
      <View
        key={index}
        style={[styles.legendItem, legendItemStyle]}
        testID={STRINGS.LEGEND_ITEM_TESTID}
      >
        <View
          style={[
            styles.legendIcon,
            { backgroundColor: legend?.svg?.fill },
            legendIconStyle,
          ]}
        />
        <Text style={[styles.labelName, labelNameStyle]}>
          {legend.label}{" "}
          <Text style={[styles.symbol, prefixStyle]}>
            {prefix}
            <Text style={[styles.labelValue, labelValueStyle]}>
              {formatViews(legend.value)}
            </Text>
          </Text>
        </Text>
      </View>
    ));
  }

  render() {
    const { data, containerStyle, styleChart } = this.props;

    return (
      <View
        style={[styles.container, containerStyle]}
        testID={STRINGS.PIE_CHART_TESTID}
      >
        <PieChart
          style={[styles.chart, styleChart]}
          data={data}
          innerRadius="60%"
          outerRadius="80%"
        />
        <View style={styles.legendContainer}>{this.renderLegend()}</View>
      </View>
    );
  }
}

export default PieCharts;
