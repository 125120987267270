const CONSTANTS = {
    THEME_PRIMARY: 'primary',
    THEME_SECONDARY: 'secondary',
    THEME_DARK: 'dark',
    SIZE_SMALL: 'small',
    SIZE_MEDIUM: 'medium',
    SIZE_BIG: 'big',
    ERROR:'error',
    WARNING:'warning',
    INFO:'info'
}
export default CONSTANTS;