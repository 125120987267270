import React, { Component, PureComponent } from "react";
import {
  View,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  Text,
} from "react-native";
import style from "./style";

interface state {
  isEnabled: boolean;
  value: boolean;
}
export interface ToggleProps {
  // single check box
  toggleText?: string;
  istoggleDisable?: boolean;
  istoggleSelected?: boolean;
  //toggle style
  toggleTextStyle?: StyleProp<TextStyle>;
  disabletoggleTextStyle?: Object;
  trackColor?: { false?: string; true?: string };
  thumbColor?: string;
  activeThumbColor?: string;
  trackContainerStyle?: object;
  thumbContainerStyle?: object;
}

class Toggle extends PureComponent<ToggleProps, state> {
  constructor(props: ToggleProps) {
    super(props);
    this.state = {
      isEnabled: props.istoggleSelected || false,
      value: false,
    };
  }

  componentDidUpdate(prevProps: ToggleProps) {
    if (prevProps.istoggleSelected !== this.props.istoggleSelected) {
      this.setState({
        isEnabled: this.props.istoggleSelected || false,
      });
    }
  }
  handleToggleSwitch = () => {
    const newValue = !this.state.value;
    this.setState({ value: newValue });
  };

  toggleSwitch = () => {
    this.setState((prevState) => ({
      isEnabled: !prevState.isEnabled,
    }));
  };

  render() {
    const {
      toggleText,
      istoggleDisable,
      toggleTextStyle,
      disabletoggleTextStyle,
    } = this.props;

    const isToggleDisableStyle = istoggleDisable
      ? [style.disabletoggleTextStyle, disabletoggleTextStyle]
      : [style.toggleTextStyle, toggleTextStyle];

    return (
      <View style={style.toggleViewStyle}>
        <View>
          <TouchableOpacity
            testID={"toggle-switch"}
            style={[
              style.switchContainer,
              this.state.value
                ? {
                    backgroundColor: this?.props?.trackColor?.true
                      ? this?.props?.trackColor?.true
                      : "#6200EA",
                  }
                : {
                    backgroundColor: this?.props?.trackColor?.false
                      ? this?.props?.trackColor?.false
                      : "#E2E8F0",
                  },
            ]}
            onPress={() => this.handleToggleSwitch()}
            activeOpacity={1}
            disabled={istoggleDisable ? true : false}
          >
            <View
              style={[
                style.thumb,
                this.props.thumbContainerStyle,
                this.state.value
                  ? {
                      right: 0,
                      backgroundColor: this.props.activeThumbColor
                        ? this.props.activeThumbColor
                        : "white",
                    }
                  : {
                      left: 0,
                      backgroundColor: this.props.thumbColor
                        ? this.props.thumbColor
                        : "white",
                    },
              ]}
            />
          </TouchableOpacity>
        </View>
        {toggleText && toggleText?.length > 0 && (
          <Text style={isToggleDisableStyle}>{toggleText}</Text>
        )}
      </View>
    );
  }
}

export default Toggle;
