import React, { Component } from "react";
import {
  StyleProp,
  TextStyle,
} from "react-native";
import CheckboxSingle from "./component/checkboxsingle";
import CheckboxMultiple from "./component/checkboxmultiple";

// single check box
export interface CheckBoxSingleProps {
  checkBoxGroup?: false;
  checkBoxText?: string;
  onCheckBoxPress?: () => void;
  isCheckBoxSelected?: boolean;
  ischeckBoxDisable?: boolean;
  checkBoxDisableSelected?: boolean;
}

// multipleCheckBox
export interface CheckBoxMultipleProps {
  checkBoxGroup: true;
  checkBoxGroupTitle?: string;
  checkBoxGroupData?: Array<object>;
  checkBoxGroupTitleStyle?: StyleProp<TextStyle>;
};

export interface CheckBoxProps {
  checkBoxGroup: boolean;
  checkBoxViewStyle?: object
  checkBoxButtonStyle?: Object;
  checkBoxTextStyle?: StyleProp<TextStyle>;
  checkBoxDisableSelectedButtonStyle?: Object;
  checkBoxDisableButtonStyle?: Object;
  disableCheckBoxTextStyle?: Object;
  rightClickIcon?: string
  viewGradientColorStyle?: Array<string>
};

class CheckBox extends Component<
  CheckBoxProps & (CheckBoxSingleProps | CheckBoxMultipleProps)
>{
  render() {
    return (
      <>
        {this?.props?.checkBoxGroup === true ? (
          <CheckboxMultiple {...this?.props} />
        ) : (
          <CheckboxSingle {...this?.props} />
        )}
      </>
    )
  }
}



export default CheckBox
