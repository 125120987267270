import React, { Component } from "react";
import { Image, TouchableOpacity, View, Text } from "react-native";
import styles from "./styles";
import Button from "../Button";
import STRINGS from "../../utils/strings";
import STRINGS_TESTCASE from "../../utils/stringsTestCase";
import Input from "../Input";

export interface PopoverInputComptProps {
  containerStyle?: {};
  iconStyle?: {};
  titleText?: string;
  titleStyle?: {};
  descriptionText?: string;
  descriptionStyle?: {};
  btnCancelStyle?: {};
  txtCancelStyle?: {};
  btnDoneStyle?: {};
  txtDoneStyle?: {};
  closeIcon?: string;
  textInputs?: Array<object>;
  gradientColor1: string;
  gradientColor2: string;
}

interface PopoverState {
  isModalVisible: boolean;
}

class PopoverInputCompt extends Component<
  PopoverInputComptProps,
  PopoverState
> {
  constructor(props: PopoverInputComptProps) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  // const [name, setName] = useState<string>('');
  // const [emailAddress, setEmailAddress] = useState<string>('');
  // const [modalVisible, setModalVisible] = useState<boolean>(true);

  onPressCloseIcon = () => {};

  onPressCancel = () => {};

  onPressDone = () => {};

  render() {
    const {
      containerStyle,
      iconStyle,
      titleText = STRINGS.POPOVER_TITLE,
      titleStyle,
      descriptionText = STRINGS.POPOVER_INPUT_TEXT,
      descriptionStyle,
      btnCancelStyle,
      txtCancelStyle,
      btnDoneStyle,
      txtDoneStyle,
      textInputs,
      closeIcon = "https://i.imgur.com/amHs6X5.png",
      gradientColor1,
      gradientColor2,
      ...props
    } = this.props;
    const { isModalVisible } = this.state;

    return (
      <View style={[styles.innerContainer, containerStyle]}>
        <TouchableOpacity
          onPress={() => this.onPressCloseIcon()}
          testID={STRINGS_TESTCASE.POPOVER_INPUT_COMPONENT_ICON}
          style={styles.iconContainer}
        >
          <Image
            source={
              typeof closeIcon === "string" ? { uri: closeIcon } : closeIcon
            }
            style={[iconStyle, styles.crossIcon]}
          />
        </TouchableOpacity>
        <Text style={[styles.txtTitleInput, titleStyle]}>{titleText}</Text>
        <View style={styles.horizontalLine} />
        <Text style={[styles.txtDescriptionInput, descriptionStyle]}>
          {descriptionText}
        </Text>

        <>
          {textInputs?.map((item: any, index: number) => (
            <View key={index} style={styles.inputContainer}>
              <Input
                placeholder={item?.placeHolder}
                label={item?.title}
                maxLength={100}
                textStyle={{ fontSize: 16 }}
                size={"big"}
              />
            </View>
          ))}
        </>

        <View style={styles.horizontalLine} />
        <View style={styles.viewBtnContainer}>
          <Button
            style={[styles.btnCancelDone, btnCancelStyle]}
            text={STRINGS.CANCEL}
            textStyle={[styles.txtCancel, txtCancelStyle]}
            onPress={() => this.onPressCancel()}
            theme={"secondary"}
          />
          <Button
            theme="primary"
            style={btnDoneStyle}
            text={STRINGS.DONE}
            textStyle={[styles.txtDelAccount, txtDoneStyle]}
            onPress={() => this.onPressDone()}
            gradientColor1={gradientColor1}
            gradientColor2={gradientColor2}
          />
        </View>
      </View>
    );
  }
}

export default PopoverInputCompt;
