import { StyleSheet } from "react-native";
import COLORS from "../../../utils/colors";
import { moderateScale, verticalScale } from "../../../utils/screenRatio";

export const styles = StyleSheet.create({
  container: {

  },
  headerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: verticalScale(10),
  },
  columnHeader: {
    color: COLORS.COLOR_GRAY,
    fontSize: moderateScale(7),
    fontWeight: "400",
    // fontFamily:"Inter-Regular",
  },
  dataRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: verticalScale(8),
    marginTop: verticalScale(8),
  },
  cell: {
    flex: 2,
    color: COLORS.COLOR_COOLGRAY,
    fontSize: moderateScale(7),
    fontWeight: "400",
    // fontFamily:"Inter-Regular",
    
  },
  cell1: {
    alignSelf: "flex-end",
    color: COLORS.COLOR_COOLGRAY,
    fontSize: moderateScale(7),
    fontWeight: "400",
    // fontFamily:"Inter-Regular",
  },
  progressRow: {
    flexDirection: "row",
    marginBottom: verticalScale(20),
  },
  progressBar: {
    height: verticalScale(10),
    borderRadius: moderateScale(20),
  },
  separator: {
    height: verticalScale(2),
    backgroundColor: COLORS.COLOR_LIGHTGRAY,
    marginBottom: verticalScale(20),
  },
  innerBar:{
    width: "100%",
    height: 10,
    backgroundColor: COLORS.COLOR_LIGHTGRAY , 
    borderRadius: 10 
  }
});
