import React from "react";
import style from "./style";
import {
  StyleProp,
  TouchableOpacity,
  View,
  TextStyle,
  Image,
  ImageStyle,
  Text,
} from "react-native";
import Input from "../../Input";
import Avatar from "../../Avatar";

export interface NavBarProps {
  headerLogoImage: string;
  headerLogoTextStyle?: StyleProp<TextStyle>;
  inputBox?: boolean;
  inputBoxPlaceholder?: string;
  inputIconName?: string;
  navRightIcon?: boolean;
  navRightIconName?: string;
  iconStyle?: ImageStyle;
  cartItem?: boolean;
  cartItemCount?: number;
  navSeconRightIcon?: boolean;
  navSeconRightIconName?: string;
  userIcon?: string;
  loginIcon?: boolean;
  source?: string;
  navButtonName?: Array<string>;
  containerStyle?: object;
  headerLogoStyle?: object;
  buttonTextStyle?: object;
  loginText?: string;
  loginTextStyle?: object;
}

class NavBar extends React.Component<NavBarProps> {
  renderButtonName = (name: string) => {
    return (
      <TouchableOpacity key={name}>
        <Text style={[style.navButtonNameStyle, this.props.buttonTextStyle]}>
          {name}
        </Text>
      </TouchableOpacity>
    );
  };

  render() {
    const {
      headerLogoImage,
      headerLogoTextStyle,
      inputBox = true,
      inputBoxPlaceholder = "search",
      inputIconName,
      navRightIcon = true,
      navRightIconName,
      iconStyle,
      cartItem = false,
      cartItemCount,
      navSeconRightIcon = true,
      navSeconRightIconName,
      userIcon,
      loginIcon = true,
      source,
      navButtonName,
      containerStyle,
      headerLogoStyle,
      loginText,
      loginTextStyle,
    } = this.props;

    const userSymbolStyle = [style.userSymbol, iconStyle];
    const symbolStyle = [style.symbol, iconStyle];

    return (
      <View style={[style.contanier, containerStyle]}>
        <View style={style.flexStyle}>
          <Image
            source={{ uri: headerLogoImage }}
            style={[headerLogoStyle as ImageStyle, style.headerLogoStyle]}
          />
          <View style={style.flexStyle}>
            {navButtonName?.map(this.renderButtonName)}
          </View>
        </View>
        <View>
          {inputBox && (
            <Input
              placeholder={inputBoxPlaceholder}
              leftIcon={inputIconName}
              size={"medium"}
              style={style.inputStyle}
            />
          )}
        </View>
        <View style={style.flexStyle}>
          {loginIcon ? (
            <View style={style.flexStyle}>
              <Image
                source={{ uri: userIcon }}
                style={userSymbolStyle as ImageStyle}
              />
              <Text style={[style.loginText, loginTextStyle]}>{loginText}</Text>
            </View>
          ) : (
            <Avatar image={source} style={style.avtarStyle} />
          )}
          {navSeconRightIcon && (
            <View>
              <Image
                source={{ uri: navSeconRightIconName }}
                style={symbolStyle as ImageStyle}
              />
            </View>
          )}
          {navRightIcon && (
            <View>
              <Image
                source={{ uri: navRightIconName }}
                style={symbolStyle as ImageStyle}
              />
              {cartItem ? (
                <View style={style.cartStyle}>
                  <Text style={style.cartText}>{cartItemCount}</Text>
                </View>
              ) : null}
            </View>
          )}
        </View>
      </View>
    );
  }
}

export default NavBar;
