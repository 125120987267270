import React, { Component } from "react";
import { View, Text } from "react-native";
import COLORS from "../../../utils/colors";
import STRINGS from "../../../utils/strings";
import { styles } from "./styles";
import LinearGradientComponent from "../../../components/LinearGradient";
interface DataItem {
  category: string;
  views: number;
  progress: number;
}

interface TableBarChartProps {
  data: DataItem[];
  leftLabelText: string;
  rightLabelText: string;
  gradientColors?: any[];
  headerContainerStyle?: Object;
  headerLabelStyle?: Object;
  rowContainerStyle?: Object;
  itemStyle?: Object;
  style?: Object;
  lineSeparatorStyle?: Object;
  progressRowView?: Object;
  progressBarStyle?: Object;
  innerProgressBarStyle?: Object;
}

class TableBarChart extends Component<TableBarChartProps> {
  constructor(props: TableBarChartProps) {
    super(props);
  }

  renderProgressRow(item: DataItem, index: number) {
    const {
      progressBarStyle,
      gradientColors = [COLORS.COLOR_LIGHTBLUE, COLORS.COLOR_MEDIUMBLUE],
      innerProgressBarStyle,
    } = this.props;

    return (
      <View style={[styles.innerBar, innerProgressBarStyle]}>
        <LinearGradientComponent
          style={{ width: `${item?.progress}%`, height: 10, borderRadius: 10 }}
          colors={gradientColors}
        >
          <View
            style={[
              { width: `${item?.progress}%`, height: 10, borderRadius: 10 },
              progressBarStyle,
            ]}
          />
        </LinearGradientComponent>
      </View>
    );
  }

  renderDataRows() {
    const { rowContainerStyle, itemStyle } = this.props;

    const formatViews = (views: number) => {
      return views.toLocaleString(); // Format the number with commas
    };

    return this.props.data.map((item, index) => (
      <React.Fragment key={index}>
        <View
          style={[styles.dataRow, rowContainerStyle]}
          testID={STRINGS.DATAROW_TESTID}
        >
          <Text style={[styles.cell, itemStyle]}>{item.category}</Text>
          <Text style={[styles.cell1, itemStyle]}>
            {formatViews(item.views)}
          </Text>
        </View>
        {this.renderProgressRow(item, index)}
      </React.Fragment>
    ));
  }

  render() {
    const {
      style,
      headerContainerStyle,
      headerLabelStyle,
      leftLabelText,
      rightLabelText,
      lineSeparatorStyle,
    } = this.props;

    return (
      <View style={style}>
        <View style={[styles.headerRow, headerContainerStyle]}>
          <Text style={[styles.columnHeader, headerLabelStyle]}>
            {leftLabelText}
          </Text>
          <Text style={[styles.columnHeader, headerLabelStyle]}>
            {rightLabelText}
          </Text>
        </View>
        <View style={[styles.separator, lineSeparatorStyle]} />
        <View testID="progressBar"> {this.renderDataRows()}</View>
      </View>
    );
  }
}

export default TableBarChart;
