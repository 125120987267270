import React, { Component } from "react";
import {
  ActivityIndicator,
  TouchableOpacity,
  Image,
  View,
  ImageBackground,
  Text,
  ViewStyle,
  TextStyle,
} from "react-native";
import styles from "./styles";
import Button from "../Button";
import LinearGradientComponent from "../LinearGradient";
import COLORS from "../../utils/colors";

export interface CardsProps {
  size?: "small" | "medium" | "big";
  containerStyle?: ViewStyle;
  lowerContainerStyle?: ViewStyle;
  iconContainer?: ViewStyle;
  iconStyle?: ViewStyle;
  iconUrl?: string;
  imageUrl?: string;
  imgStyle?: object;
  title?: string;
  titleTxtStyle?: TextStyle;
  description?: string;
  descTxtStyle?: TextStyle;
  price?: string;
  priceTxtStyle?: TextStyle;
  btnText?: string;
  btnStyle?: ViewStyle;
  gradientColor1?: string;
  gradientColor2?: string;
  children?: React.ReactChild;
}

class Cards extends Component<CardsProps> {
  render() {
    const {
      size = "",
      containerStyle,
      lowerContainerStyle,
      iconContainer,
      iconStyle,
      iconUrl,
      imageUrl = "",
      imgStyle,
      title = "",
      titleTxtStyle,
      description = "",
      descTxtStyle,
      price = "",
      priceTxtStyle,
      btnText,
      btnStyle,
      gradientColor1 = "",
      gradientColor2 = "",
      children,
      ...props
    } = this.props;

    const smallCard = () => {
      return (
        <>
          <View
            style={[
              styles.smallCardContainer,
              styles.shadowProp,
              containerStyle,
            ]}
          >
            {iconUrl && (
              <Button
                theme="secondary"
                style={[styles.mainIconCnt, iconContainer]}
                icon={iconUrl}
                iconStyle={[styles.icon, iconStyle]}
              />
            )}
            <Image
              source={
                typeof imageUrl === "string" ? { uri: imageUrl } : imageUrl
              }
              style={[styles.imgSmall, imgStyle]}
            />
          </View>
          <View style={[styles.txtViewContainer, lowerContainerStyle]}>
            <Text style={[styles.txtTitle, titleTxtStyle]}>{title}</Text>
            <Text style={[styles.txtDesc, descTxtStyle]}>{description}</Text>
          </View>
        </>
      );
    };

    const mediumCard = () => {
      return (
        <View
          style={[
            styles.mediumCardContainer,
            styles.shadowProp,
            containerStyle,
          ]}
        >
          {iconUrl && (
            <Button
              theme="secondary"
              style={[styles.mainIconCnt, iconContainer]}
              icon={iconUrl}
              iconStyle={[styles.icon, iconStyle]}
            />
          )}
          <Image
            source={typeof imageUrl === "string" ? { uri: imageUrl } : imageUrl}
            style={[styles.imgMedium, imgStyle]}
          />
          <Text style={[styles.txtTitleMedium, titleTxtStyle]}>{title}</Text>
          <Text style={[styles.txtDescMedium, descTxtStyle]}>
            {description}
          </Text>
          <Text style={[styles.txtPrice, priceTxtStyle]}>{price}</Text>
        </View>
      );
    };

    const bigCard = () => {
      return (
        <ImageBackground
          source={typeof imageUrl === "string" ? { uri: imageUrl } : imageUrl}
          style={[styles.bigCardContainer, styles.shadowProp, containerStyle]}
        >
          <LinearGradientComponent
            colors={
              gradientColor1 && gradientColor2
                ? [gradientColor1, gradientColor2]
                : ["rgba(98, 0, 234, 0.00)", COLORS.COLOR_MEDIUMBLUE]
            }
            style={{ ...styles.gradientOverlay }}
          >
            <View style={{ flex: 1 }}>
              <Text style={[styles.txtTitleBig, titleTxtStyle]}>{title}</Text>
              <Text style={[styles.txtDescBig, descTxtStyle]}>
                {description}
              </Text>
              {btnText && (
                <Button
                  theme="dark"
                  style={[styles.buttonStyle, btnStyle]}
                  text={btnText}
                />
              )}
            </View>
          </LinearGradientComponent>
        </ImageBackground>
      );
    };

    const taskCard = () => {
      return (
        <View
          style={[styles.taskCardContainer, styles.shadowProp, containerStyle]}
        >
          <View style={[styles.viewTaskTxtImgCont]}>
            <View style={[styles.viewTaskBorderCont]}></View>
            <View style={[styles.taskCardTxtCont]}>
              <Text style={[styles.txtTaskTitle, titleTxtStyle]}>{title}</Text>
              <Text style={[styles.txtTaskDesc, descTxtStyle]}>
                {description}
              </Text>
              <Text style={[styles.txtTaskDesc, priceTxtStyle]}>{price}</Text>
            </View>
            <View style={[styles.viewChildren]}>{children}</View>
          </View>
        </View>
      );
    };

    return size == "small"
      ? smallCard()
      : size == "medium"
      ? mediumCard()
      : size == "big"
      ? bigCard()
      : taskCard();
  }
}

export default Cards;
